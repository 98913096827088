@import "/src/assets/scss/variables";

.remove-wrapper {
    position: relative;

    .btn-remove {
        position: absolute;
        top: 0.75rem;
        right: -2.75rem;
        z-index: 2;
        color: $secondary-color;

        &:hover {
            color: red;
        }

        @media(max-width: 767px) {
            right: 0;
            bottom: -90px;
        }
    }


}


.add-btn {
    @media(min-width: 768px) and (max-width: 992px) {
        height: 50px;
    }
}

// .remove-wrapper .btn-remove {

//     @media(max-width: 767px) {
//         position: relative !important;
//         float: right;
//         margin-right: 30px;
//     }
// }