@import "../../assets/scss/variables";

.image-upload {
    display: flex;
    justify-content: space-between;



    @media (max-width: 991px) {
        &.modal-custom-section .modal-dialog {
            max-width: 700px;
        }
    }

    .img-upload-modal-rhs {
        max-width: 200px;

        @media (max-width: 575px) {
            margin: 0 auto 15px;
        }
        .ReactCrop {
            &--fixed-aspect {
                div:first-child {
                    width: 150px;
                    height: 150px;
                    img {
                        max-width: 150px;
                        max-height: 150px;
                        // margin: 0 auto;
                    }
                }
            }
        }
    }

    .ReactCrop {
        margin: 0 auto;
    }

    .img-upload-modal-lhs {
        @media (max-width: 575px) {
            display: flex;
            flex-direction: column-reverse;
            ul {
                margin-bottom: 0;
            }
        }

        .ReactCrop__image {
            object-fit: cover !important;
        }

        ul {
            li {
                margin-bottom: 15px;

                &:last-child {
                    margin-bottom: 5px;
                }
            }
        }

    }

    .selected-img {
        color: $primary-color;
        margin-bottom: 0;

        @media (max-width: 575px) {
            text-align: center;
        }
    }

    .upload-btns {

        cursor: pointer;
        padding-top: 10px;
        font-size: 1.4rem;
        text-align: center;
        color: var(--secondary-cvltr-blue-color);
    }

    .preview-section {
        height: 100px;
        width: 100px;
        border: 1px #ccc solid;
        margin: 0 auto;

        canvas {
            width: 100%;
        }

        @media (max-width: 767px) {
            display: none;
        }
    }

    .modal-footer {
        justify-content: right !important;

        @media (max-width: 767px) {
            justify-content: center !important;
        }
    }


}