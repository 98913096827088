@import "../../assets/scss/variables";

.checkout {
    // margin: 48px 0 60px;

    @media (max-width: 991px) {
        margin: 30px 0 10px;
    }

    @media (max-width: 767px) {
        margin: 20px 0 10px;
    }

    // @media screen and (min-width: 1200px) and (max-width: 1599px) {
    //     margin: 40px 0 60px;
    // }

    .container {
        position: relative;
        padding: 0 25px;

        @media screen and (min-width: 1200px) and (max-width: 1599px) {
            padding: 0 20px;
        }

        @media (max-width: 459px) {
            padding: 0 15px;
        }
    }



    .card-details {
        border: 1px solid #ee7856;
        border-radius: 3px;
        box-shadow: 0 2px 35px rgb(0 0 0 / 10%);
        padding: 15px;
        max-width: 625px;

        @media (max-width: 991px) {
            max-width: 100%;
        }

        @media (max-width: 767px) {
            padding: 25px 25px 10px;
        }

        @media (max-width: 459px) {
            border-radius: 0px;
            box-shadow: none;
            padding: 13px;
        }

        @media screen and (min-width: 1200px) and (max-width: 1599px) {
            max-width: 633px;

            margin-bottom: 10px;
        }

        h1 {
            font-size: 2.64rem;
            font-family: "Rubik", sans-serif;
            font-weight: 500;
            text-transform: capitalize;
            margin-bottom: 1.65rem;

            @media (max-width: 767px) {
                font-size: 1.8rem;
                margin-bottom: 1.5rem;
            }
        }
    }

    .checkoutpage-user-logo {
        position: relative;

        // &::before {
        //     content: "\f007";
        //     font-family: "FontAwesome";
        //     position: absolute;
        //     color: #EBEBEB;
        //     left: 20px;
        //     bottom: 3rem;

        //     width: 20px;

        //     @media (min-width:1200px) and (max-width:1599px) {
        //         bottom: 4rem;

        //     }

        //     // background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 25 25' fill-rule='evenodd'%3E%3Cpath d='M16.036 18.455l2.404-2.405 5.586 5.587-2.404 2.404zM8.5 2C12.1 2 15 4.9 15 8.5S12.1 15 8.5 15 2 12.1 2 8.5 4.9 2 8.5 2zm0-2C3.8 0 0 3.8 0 8.5S3.8 17 8.5 17 17 13.2 17 8.5 13.2 0 8.5 0zM15 16a1 1 0 1 1 2 0 1 1 0 1 1-2 0'%3E%3C/path%3E%3C/svg%3E") center / contain no-repeat;
        // }
    }


    .payment__cards-container {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin: 1.125rem 0 0rem;
        position: relative;

        .radio-btn {
            margin-right: 3px;
            width: 32px;
            height: 24px;
            border: 1px solid transparent;
            display: inline-block;
            position: relative;
            text-align: center;
            cursor: pointer;

            i {
                color: #fff;
                background-color: #27ae60;
                font-size: 12px;
                position: absolute;
                top: -12px;
                right: -22px;
                transform: translateX(-50%) scale(4);
                border-radius: 50px;
                padding: 3px;
                transition: .2s;
                pointer-events: none;
                opacity: 0;
            }
        }


        @media (max-width: 991px) {
            margin: 1rem 0;
            margin-bottom: -35px;
            justify-content: end;
        }

        .payment__cards {
            margin: 0;
            padding: 0;

            justify-content: flex-end;
            display: flex;

            li {
                cursor: pointer;
                list-style: none;
                margin-right: 0.5rem;


                &.jbc-card {
                    img {
                        width: 4.25rem !important;
                        margin-left: -2px;
                    }
                }

                div {
                    img {
                        width: 6rem;
                        padding: 3px;
                        height: 23px;
                        border: 1px solid #ededed;

                        @media (max-width:1200px) and (min-width:993px) {
                            height: 28px;
                        }

                        @media (max-width:991px) {
                            width: 5rem;
                        }
                    }
                }

            }



            img {
                // border-radius: 4px;
                // display: block;
                // height: auto;
                // width: 2.5rem;

                border-radius: 4px;
                display: block;
                height: auto;
                width: 4.1rem;
                padding: 1px;
                border: 1px solid rgb(210, 210, 210);
            }

            .card-border {
                position: relative;

                img {
                    border-color: #65c897;
                }

                &:after {
                    position: absolute;
                    right: -5px;
                    top: -8px;
                    content: "\f058";
                    color: #65c897;
                    font-size: 11px;
                    font-family: FontAwesome;
                    background-color: transparent;
                    border-radius: 100%;
                }
            }



            @media (max-width:991px) {
                display: none;
            }
        }

        .payment-options {
            position: absolute;
            top: -5px;
            right: 0;
            width: 110px;
            height: 42px;
            line-height: 42px;
            border: 1px solid #ccc;
            text-align: center;
            cursor: pointer;
            border-radius: 5px;

            img {
                height: 20px;
                margin-top: -3px;
            }
        }
    }

    .payment-footer {
        margin: 0;
        max-width: 633px;

        @media (max-width: 459px) {
            margin-top: 10px;

        }

        @media (max-width: 992px) {
            font-size: 14px;
            padding-bottom: 0;

        }


    }

    .card-bottom {
        margin: 30px 0px;
        max-width: 633px;
        font-family: "Roboto", sans-serif;
        font-weight: normal;
        font-size: 14px;

        @media (max-width: 459px) {
            margin-top: 10px;
        }

        p {
            margin-bottom: 1em;
        }

        h5 {
            font-family: "Open Sans TTF", sans-serif;
            font-weight: bold;
            font-size: 14px;
        }
    }

    .payment-info-container {
        // padding-left: 47px;


        @media (max-width: 992px) {
            padding-left: 15px;
            padding-top: 20px;
        }

        .payment-header {
            // padding-bottom: 1.75rem;

            h3 {
                padding-top: 3px;
                font-size: 1.8rem;
                margin-top: 0;
                margin-bottom: 1rem;
                font-weight: 700;
                font-family: "Open Sans TTF", sans-serif;
            }

            p {
                font-size: 15px;
                line-height: 21px;
            }

            h5 {
                margin: 0 0 10px 0;
                color: #424a5b;
                font-family: "Poppins", sans-serif;
                font-weight: 700;
                font-size: 19px;

                @media (max-width:991px) {
                    font-size: 15px;
                }
            }

            @media (max-width:767px) {
                padding-top: 20px
            }
        }



        .payment-info {
            padding-bottom: 0;



            .payment-info-plan {
                font-size: 1.8rem;
            }

            ul {
                margin: 0;
                padding: 0;

                li {
                    list-style: none;
                    margin-bottom: 2rem;
                    padding-left: 27px;
                    position: relative;

                    &::before {
                        position: absolute;
                        left: 0;
                        color: #2ebe6e;
                        content: '\f00c';
                        font-family: 'FontAwesome';
                        padding-right: 5px;
                    }
                }
            }
        }

        .payment-summary {
            border-bottom: 2px solid #EBEBEB;
            padding-top: 3.5rem;
            padding-bottom: 3.5rem;

            &--total {
                color: $secondary-color;
            }
        }

        .payment-footer {
            border-bottom: 2px solid #EBEBEB;
        }


    }
}

.trust-review-widget {

    text-align: center;
    margin-top: -15px;
    border-bottom: 2px solid #EBEBEB;

    @media (max-width:767px) {
        margin-top: 0;
    }

    .trustpilot {
        width: 250px;
        margin: 0 auto;
        padding-bottom: 10px;
    }
}

.payment-forms.checkout {

    .form-control,
    select {
        padding: 0;
        min-height: 46px;
    }

    #checkout_form_card_name,
    #expmo,
    #expyr {
        padding-top: 10px;
    }

    .ssl-logo {
        text-align: right;

        @media (max-width: 767px) {
            text-align: center;
        }

        img {
            max-width: 70px;
        }
    }

    .hidden {
        visibility: hidden;
    }

    .show {
        visibility: visible;
    }







    #tokenExIframeDiv {
        height: 45px;
        padding-top: 17px;

        iframe#tx_iframe_tokenExIframeDiv {
            width: 100%;
            height: 22px;
            margin-top: 5px;
            background: #fff;
        }
    }


    #card-cvv-element {
        height: 41px;
        padding-top: 19px;
        padding-right: 22px;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAASCAMAAACDzGUcAAAAWlBMVEUAAACSkpKioqKZmZmdnZ2Xl5ebm5uampqZmZmampqYmJiYmJiZmZmZmZmZmZmampqZmZmampqZmZmZmZmZmZmYmJiZmZmYmJiZmZmZmZmZmZmZmZmZmZn///+dUP3mAAAAHHRSTlMABwsUGhscRFVlaHd7gIWIjI2Olpmft7i5u93uIEiFKQAAAAFiS0dEHesDcZEAAABdSURBVBjTrZDLEoAgCEXpYW8zS0iz///OWDairTrLM8MFLkAW5a8Ur2CnPoUsoBbjGgHDnBLY3pKijeKGiKVtf9jOuaOeHLO8bLMaXQ2GGb8TbKadDdQpvqA2X/oD4jUQYFUBAdcAAAAASUVORK5CYII=) no-repeat right 20px;
    }


    .secure-msg {
        margin-top: -10px;

        @media (max-width:767px) {
            margin-top: -20px;
        }
    }

    .flagger {
        font-size: 13px;
        display: block;
    }

    #acceptCheckboxFlag.flagger {
        font-size: 11px;
        display: block;
        line-height: 10px;
        font-weight: 500;
        padding-top: 10px;
        text-transform: uppercase;
        letter-spacing: -.2px;
    }

    .red {
        color: red;
    }

    .small {
        font-size: 12px;
        padding-top: 18px;

        // @media (max-width: 767px) {
        //     display: none;
        // }

        img {
            margin-right: 4px;
            float: left;
            margin-top: 2px;
        }
    }

    .exp-date-year {


        @media (max-width: 459px) {
            margin-bottom: 0;
        }

        #expYearDiv {
            padding-left: 0;

        }
    }

    #CVV {
        @media (max-width:767px) {
            .cvvspan {
                display: none;
            }
        }


    }


    .form-control {
        padding: 0;
        color: #222;
        outline: none;
        font-size: inherit;
        line-height: 1.5;
        border: none;
        border-radius: 0;
        border-bottom: 2px solid #c1c0c5;

    }



    select {
        border: none;
        border-radius: 0;
        border-bottom: 2px solid #c1c0c5;
    }

    .secureHeading {

        color: #424a5c;
        font-weight: 500;


        @media (max-width:767px) {
            font-size: 10.5px
        }
    }

    .logocontainer {
        display: flex;
        justify-content: end;

        .nortonSeal {
            max-width: 80px;
            justify-content: end;
            margin-right: -17px;

            @media (max-width:767px) {
                width: 61px;
                height: 31px;
                margin-top: 13px;
            }
        }
    }


}

.all-card {
    width: 120px;
    display: flex;



    img {
        height: 25px;
        width: 40px;
    }

    @media (max-width: 991px) {
        margin-bottom: 22px;
        width: 11 8px;
        display: show !important;
    }

    @media (min-width:992px) {

        display: none !important;
    }

    .paypal-styles {
        padding-left: 13px;
        width: 150px;
        margin-top: -2px;
        /* height: 25px; */
        /* padding-top: 2px; */
        margin-left: 10px;
        border-radius: 5px;
        border: 1px solid #ededed;


    }
}

.payment-forms label {
    font-size: 11.5px;
    margin-bottom: 0;
    padding-bottom: 0;
    font-weight: 500;
    text-transform: uppercase;

    color: #9b9b9b;

    @media (max-width:767px) {
        font-size: 1.1rem;
    }
}

.check-error {
    label {
        color: red;

        &::after {
            content: "";
            font-family: "FontAwesome";
            color: red;
            font-size: 12px;
            position: absolute;
            margin: 0px 0px 0px 3px;
        }
    }
}

.check-passed {
    label {
        &::after {
            content: "";
            font-family: "FontAwesome";
            color: #65c897;
            margin: 0 0 0 3px;
            font-size: 12px;
        }
    }
}

.tooltip-wrap {
    position: absolute;
    top: -52px;
    right: 27px;
    cursor: pointer;

    @media(max-width:767px) {
        display: none;
    }
}

.ssl-tooltip {
    position: relative;
    display: flex;
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    padding: .5rem .5rem;

    svg {
        fill: #FBB231;
    }
}

.ssl-tooltip-btn {
    line-height: 14px;
    text-align: left;
    padding-left: 10px;
    letter-spacing: 0.4px;

    small {
        letter-spacing: 0;
    }
}

.ssl-tooltip .ssl-tooltiptext {
    visibility: hidden;
    width: 400px;
    background-color: black;
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 15px 10px;
    position: absolute;
    z-index: 1;
    top: 0;

    right: 180px;
}

.tooltip-inner {
    font-size: 1.6rem;
    font-weight: 400;
    max-width: 350px;
    padding: 1rem;
    border-radius: 6px;
}

.navbar-toggler {
    display: none;
}

.flex-md-row {
    @media (min-width: 768px) {
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }
}