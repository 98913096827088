.cvltr-layout.cvltr-4 h1 {
  margin-bottom: 0;
  margin-top: 0;
  font-weight: bold;
  text-transform: capitalize;
  font-family: "Merriweather",serif;
}
.cvltr-layout.cvltr-4 {
  font-family: "Merriweather",serif;
}
.cvltr-layout.cvltr-4 .cvltr-info {
  display: flex;
}
.cvltr-layout.cvltr-4 .cvltr-dotted-border {
  border-bottom: 3px solid #000;
  border-bottom-style: dotted;
}
.cvltr-layout.cvltr-4 .cvltr-info p {
  margin-right: 5px;
}
.cvltr-layout.cvltr-4 .cvltr-companyinfo p {
  margin-bottom: 5px;
  margin-top: 0;
}
.cvltr-layout.cvltr-4 .cvltr-mt30 {
  margin-top: 30px;
}
.cvltr-layout.cvltr-4 .cvltr-mb30 {
  margin-bottom: 30px;
}
.cvltr-layout.cvltr-4 .cvltr-lable {
  display: none;
}
.cvltr-layout.cvltr-4 .cvltr-subheading {
  display: none;
}
.cvltr-layout.cvltr-4 .cvltr-emailnone {
  display: none;
}
.cvltr-layout.cvltr-4 .cvltr-img {
  display: none;
}
.cvltr-layout.cvltr-4 .cvltr-emaildisplay {
  display: none;
}
.cvltr-layout.cvltr-4 .cvltr-emailnone {
  display: block;
}
.cvltr-layout.cvltr-4 .cvltr-display-flex {
  display: flex;
  align-items: center;
}
.cvltr-layout.cvltr-4 .cvltr-d-flex {
  display: flex;
  align-items: center;
}
.cvltr-layout.cvltr-4 .cvltr-headinginfo p {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 2px;
}
.cvltr-layout.cvltr-4 .cvltr-mail-spacing {
  margin-bottom: 30px;
}
.cvltr-layout.cvltr-4 .cvltr-dotted-border h1 {
  margin-bottom: 10px;
}
.cvltr-layout.cvltr-4 .cvltr-information {
  display: none;
}
.cvltr-layout.cvltr-4 .cvltr-dother-none {
  display: none;
}
.cvltr-layout.cvltr-4 .flexwrap{
  flex-wrap: wrap !important;
}
.cvltr-layout.cvltr-4 .cvltr-display-flex{
  display: block;
}
.cvltr-layout.cvltr-4 .show-comma {
  display: none;
}