@import '/src//assets//scss/variables';


.job_description {
    margin-top: 2.4rem
}

.writer {
    display: flex;
    border: 1px solid #c1c0c5;

    &.other-editor {
        .col-sm-6 {
            max-width: 100%;
            width: 100%;
        }
    }
}

.writer .formatted_content {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
}

.writer__textarea {
    border: 0;
    display: block;
    padding-top: 1.2rem
}

.writer__textarea:focus {
    border: 0;
    box-shadow: none
}

@media (min-width: 992px) {

    .writer__textarea,
    .writer__snippet-wrapper {
        flex-basis: 50%
    }
}

@media (min-width: 992px) {
    .writer__textarea.all_width {
        flex-basis: 100%
    }
}

.writer__snippet-wrapper {
    border-left: 1px solid #c1c0c5;
    background-color: white;
    flex-direction: column;
    display: none
}

@media (min-width: 768px) {
    .writer__snippet-wrapper {
        display: flex
    }
}

.writer__snippet-options {
    border-bottom: 1px solid #c1c0c5;
    flex-shrink: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #fff
}

.writer__snippet-options>* {
    width: 49%;
    padding: .95rem
}

.writer__snippet-options .writer__snippet-intro {
    background: #f2f2f2;
    width: 100%;
    flex-basis: auto;
    border-bottom: 1px solid #c1c0c5;
    padding-bottom: .5em;
    margin-bottom: .5em;
    padding: 0.8rem;
    font-size: 1.4rem
}

.writer__snippet-options .writer__snippet-intro p {
    margin-bottom: 0
}

.writer__snippet-options .select2-selection {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.writer__snippet-inner {
    flex-grow: 1;
    padding: 0;
    height: auto;
    //max-height: 300px;
    min-height: 335px;
    max-height: 366px;
    overflow: auto;

    // @media (min-width:1600px) {
    //     min-height: 316px;
    //     max-height: 305px
    // }
}

.writer__snippet-inner .writer__snippet-container {
    padding: 1rem
}

.writer__snippet {
    cursor: pointer;
    border: 1px solid #c1c0c5;
    font-size: 1.4rem;
    // padding: .5em .5em .5em 2em;
    margin-bottom: 1rem;
    background-color: white;
    position: relative;
    transition: .3s transform
}

.DraftEditor-editorContainer {
    background-color: rgba(255, 255, 255, 0);
    border-left: 0.1px solid transparent;
    position: relative;
    z-index: 1;
    max-height: 418px;
}

// .writer__snippet::before {
//     color: #fff;
//     content: "";
//     position: absolute;
//     top: -1px;
//     bottom: -1px;
//     left: -1px;
//     width: 20px;
//     background-color: #2ebe6e
// }

// .writer__snippet::after {
//     content: "ADD";
//     position: absolute;
//     top: 50%;
//     transform: translateY(-50%);
//     margin-top: -8px;
//     left: 0;
//     font-size: 10px;
//     color: #fff;
//     transform: rotate(-90deg);
//     z-index: 5
// }

.writer__snippet:hover {
    transform: translateX(-10px)
}

.writer__snippet-wrapper {
    .add-label-wrap {
        display: flex;
        margin-bottom: 1rem;
        min-height: 49px;
        transition: .3s transform;
    }

    .add-label {
        font-size: 9px;
        color: #fff;
        z-index: 5;
        width: 22px;
        background-color: #2ebe6e;
        position: relative;
        padding: 0px 10px;
        margin-left: -1px;
        margin-top: -1px;
        margin-bottom: -1px;

        span {
            position: absolute;
            top: 50%;
            word-break: initial;
            left: 50%;
            transform: translateX(-50%) translateY(-50%) rotate(-90deg);
        }
    }

    .add_text_content {
        padding: .5em .5em .5em .5em;
    }
}

// TEXT EDITOR
.rdw-editor-main {
    height: auto;
    min-height: 335px;
    //max-height: 335px;
    max-height: 410px;
    overflow: hidden;
    box-sizing: border-box;
    word-break: break-word;
    padding-right: 10px;
}

.public-DraftEditorPlaceholder-hasFocus {
    display: none;
}

.add-exp-btn {
    color: #2ebe6e;
    background-color: transparent;
    text-transform: uppercase;
    border-color: #2ebe6e;
    font-size: 1.8rem;
    padding: 1.55rem 4.5rem;

    @media (max-width: 767px) {
        margin-top: 10px;
        order: -1 !important;
        margin-bottom: 1rem;
        width: 100% !important;
        padding: 1.55rem 2rem;
    }

    @media (max-width:376px) {
        font-size: 1.47rem;
        padding: 1.55rem 2rem;
    }

    // @media(max-width:992px) {
    //     height: 92%;
    //     font-weight: $font-weight-medium;
    // }

    // @media (max-width:767px) {
    //     height: 40%;
    // }
}

.add-exp-btn:hover,
.add-exp-btn:active {
    color: #fe8c26;
    background-color: white;
    border-color: #fe8c26;
    outline: 0;
    text-decoration: none;
}

input[type="checkbox"] {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

input[type="checkbox"]+label {
    display: inline-block;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-right: 0.5em;
    text-transform: initial;
    letter-spacing: initial;
}

input[type="checkbox"]+label::before {
    content: "";
    display: inline-block;
    margin-right: 0.3em;
    vertical-align: middle;
    width: 1em;
    height: 1em;
    font-size: 1.35em;
    transition: all 0.3s ease-out;
    transform: scale(1);
    color: #EBEBEB;
}

input[type="checkbox"]+label::before {
    text-decoration: none;
    display: inline-block;
}

input[type="checkbox"]:checked+label::before {
    content: "";
    border: 0;
    transform: scale(1);
    color: #2ebe6e;
}

#experience_end_year option:first-child,
#experience_end_month option:first-child,
#experience_start_year option:first-child,
#experience_start_month option:first-child {
    display: none;
}

.custom-section .col-sm-6 {
    width: 100%;
}

.exp-header {
    // border-bottom: 1px solid #c1c0c5;
    display: flex;
    // margin-bottom: 16px;
    // margin-right: 45px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    font-family: "Rubik", sans-serif;
    border-color: transparent;


    //  @media (max-width:991px)
    //  {
    //      margin-bottom: 50px;
    //  }
    // @media (max-width:767px)
    // {
    //     margin-top: 0px;

    // }
    // hgroup {
    //     span {
    //         font-size: 0.8rem;
    //     }
    // }
}

.text-container {
    padding-left: 50px;
    padding-right: 60px;

    @media (max-width: 992px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.exp-back-btn {
    margin-left: 6px;

    @media (max-width: 767px) {
        margin-left: 4px !important;
        order: 0 !important;
    }

    @media(max-width: 992px) {
        margin-left: 0px;
    }
}

.exp-save-btn {
    margin-right: -9px;

    @media(max-width: 992px) {
        margin-right: 13px;
    }

    @media (max-width:767px) {
        margin-right: 0px;
    }
}

.exp-save-btns {
    margin-right: -9px;

    @media(max-width: 991px) {
        margin-right: 5px;
        padding: 17px 63px;

    }

    @media (max-width:767px) {
        padding: 13px 55px !important;

    }
}

// .text-editor-container {
//     width: 98.5%;
//     @media (max-width: 767px) {
//         margin-left: 14px;
//         width: 94% !important;
//     }
//     @media (max-width:992px) {
//         margin-left: 10px !important;
//         width: 97.5%;
//     }
// }

.text-editor-align {
    @media (max-width: 767px) {
        margin-left: 0px;
        margin-right: 0px;
    }
}

.edu-save-btn {
    @media (max-width: 767px) {
        margin-right: 0px;
    }
}

.edu-back-btn {
    @media (max-width: 992px) {
        margin-left: 0px;
    }

    @media (max-width:767px) {
        margin-left: 0px;
    }
}

.editor-box {
    padding-top: 40px;
    padding-left: 10px;
}
