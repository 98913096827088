/* error-modal.scss */

.custom-modal-width{
    --bs-modal-width: 420px;
}

.error-modal .modal-content {
    border: none;
}

.error-modal .modal-header {
    border-bottom: none;
}

.error-modal .modal-footer {
    border-top: none;
}
.error-modal .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.error-modal .error-message {
    color: red;
}

.error-modal .modal-footer .btn-refresh {
    width: 50%;
    border-radius: 5px;
}

.error-modal .closeButton {
    margin: 10px 10px 0px 10px;
    padding: 5px;
}


.error-modal .modal-content {
    border-radius: 10px;
}

.error-modal .modal-backdrop {
    background-color: rgb(107 114 128);
    opacity: 0.75;
}

.error-modal .warning-message{
    font-size: 13px;
}