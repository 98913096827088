@import "/src/assets/scss/variables";

.cvltr-wrapper .cvltr-view-layout { padding: 3em 3em 1em !important; }

.review {
  @media (max-width: 1199px) {
    padding: 15px 5px;
  }

  @media (max-width: 991px) {
    padding: 15px 5px;
  }

  @media (max-width: 767px) {
    padding: 15px 0;
  }
}

.resume-buttons {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  margin-bottom: 2rem;

  .download-resume,
  .print-btn {
    width: 48%;
    padding: 1.55rem 1rem;

    @media (max-width: 767px) {
      width: 49%;
    }
  }
}

// .resume-buttons>* {
// 	// display: flex;
// 	// width: 100%;

// 	// @media (max-width:767px) {
// 	// 	display: flex;
// 	// }
// }

// .resume-buttons>*:first-child {

// 	margin-right: 1.2rem;

// }

.resume-wrapper {
  border: 1px solid #c1c0c5;
  margin-bottom: 2rem;

  // overflow: auto;
  @media (min-width: 992px) {
    background-color: transparent;
    padding: 0;
    border: 0;
  }
}

.cvltr-wrapper {
  border: 1px solid #c1c0c5;
  margin-bottom: 2rem;

  // overflow: auto;
  @media (min-width: 992px) {
    background-color: transparent;
    padding: 0;
    border: 0;
  }
}

.fa-downlaod-icon {
  @media (max-width: 767px) {
    margin-top: 5px;
  }
}

.fa-print {
  @media (max-width: 767px) {
    margin-top: 5px;
  }
}

.add-section-btns {
  border-color: $secondary-color;
  margin-top: 10px;
  color: $secondary-color;
  font-size: 1.3rem;
  text-transform: uppercase;
  font-weight: $font-weight-semi;
  padding: 1em 1em;

  @media (max-width: 991px) {
    height: 50px;
    padding: 0em 1em;
  }
}

.add-section-btns:hover {
  background-color: white;
  border-color: $primary-color-dark;
  color: $primary-color-dark;
}

// .resume-functionality-btn {
// 	@media (max-width:992px) {
// 		width: 45%;
// 		padding: 18.5px 17px !important;
// 	}

// 	@media (max-width:767px) {
// 		padding: 16px 45px !important;

// 	}

// }

.resume {
  background-color: white;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  border-width: 20px;
  height: 100%;
  padding-bottom: 1em;

  @media (min-width: 992px) {
    border-width: 1px;
  }

  .info-text {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
  }

  // .btn-green {
  //   border-radius: 0
  // }
}
.cvltr-view {
  background-color: white;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  border-width: 20px;
  height: 100%;
  padding-bottom: 1em;

  @media (min-width: 992px) {
    border-width: 1px;
  }

  .info-text {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
  }

  // .btn-green {
  //   border-radius: 0
  // }
}

.resume-layout {
  display: block;
  min-height: 1105.79px;
}
.cvltr-view-layout {
  display: block;
  min-height: 1105.79px;
}

.resume-sidebox-wrapper {
  max-width: 100%;

  .box__header {
    background: #657489;
    color: #fff;
    padding: 1.2rem;
    margin: -1.2rem -1.2rem 0;
  }

  .box {
    background: white;
    border: 1px solid #c1c0c5;
    border-radius: 0;
  }

  .box__content {
    padding: 2.4rem 2.4rem 0;
  }
}

.resume-sidebox.box {
  padding: 1.2rem;
}

.resume-sidebox {
  margin-bottom: 2rem;
}

.box__header--small .box-title {
  margin-bottom: 0;
  text-transform: uppercase;
}

.box__header--small h4 {
  font-weight: bold;
}

.resume-sidebox .resume-sidebox__inner {
  max-height: 406px;
  overflow: auto;
}

.sb-template__change {
  margin-left: -1.2rem;
  margin-right: -1.2rem;
  margin-bottom: -1.2rem !important;
}

// Hover effect
.cvltr-view-layout-box {
  .resume__section {
    position: relative;
    //font-size: 0.8750rem;
    margin-bottom: 3.25rem;

    .resume__content {
      position: relative;
      z-index: 99;
    }

    &:hover {
      .resume__controls,
      .controls-menu,
      .resume__move {
        display: block;
      }
    }

    .resume__controls {
      position: absolute;
      top: -27px;
      bottom: -10px;
      left: -1.5rem;
      right: -1rem;
      border: 2px solid #2ebe6e;
      box-shadow: 0 0 10px rgb(0 0 0 / 25%);
      z-index: 10;
    }

    .controls-menu {
      position: absolute;
      right: 0;
      top: -27px;
      right: -1rem;
      z-index: 999;

      button.resume__control-button {
        position: relative;
      }

      button.resume__control-button:not(:last-child)::after,
      button.resume__control-button:not(:last-child)::after {
        bottom: 0;
        content: "";
        border-right: 1px solid rgba(255, 255, 255, 0.65);
        margin: auto;
        position: absolute;
        right: -2px;
        top: 0;
        height: calc(100% - 15px);
        z-index: 5;
      }
    }

    .resume__move {
      position: absolute;
      top: -26px;
      left: -1.5rem;
      z-index: 9999;
      width: 35px;
    }

    .resume__controls,
    .controls-menu,
    .resume__move {
      display: none;

      font-size: 12px;

      .btn-green {
        color: white;
        background-color: #2ebe6e;
        border-color: #2ebe6e;
        display: inline-block;
        letter-spacing: 0.01em;
        padding: 5px 8px;
        touch-action: manipulation;
        cursor: pointer;
        background-image: none;
        border: 1px solid transparent;
        font-size: 1.4rem;
      }

      .btn-green:hover,
      .btn-green:focus,
      .btn-green:active {
        color: #2ebe6e;
        background-color: white;
        border-color: #2ebe6e;
        outline: 0;
        text-decoration: none;
      }

      a {
        &.resume__control-button {
          border-radius: 0;
          border-width: 2px;
          text-transform: inherit;
          position: relative;
          top: -2px;
          right: -2px;
          z-index: 4;
          left: 0;
          height: 31px;
          font-size: 13px;
        }
      }
    }
  }
}

// .review-container {
// 	width: 1200px;
// 	margin-bottom: 50px;
// 	@media(max-width:991px) {
// 		width: auto;
// 	}
// }
.dashboard-item {
  .cvltr-view-layout-box {
    .resume__section {
      &:hover {
        .resume__controls,
        .controls-menu,
        .resume__move {
          display: none;
        }
      }
    }
  }
}

.rdw-editor-main h1 {
  font-size: 1.2em;
}

.resume__section.dragging {
  opacity: 0.5;
}

.resume__section.dragging .resume__controls {
  display: block !important;
  opacity: 0.5;
}

.resume__section.dragging .controls-menu {
  display: block !important;
}

.resume__section.dragging .resume__move {
  display: block !important;
}

@media (max-width: 767px) {
  .cvltr-view-layout-box .resume__section .resume__controls {
    left: -2rem;
    right: -2rem;
  }

  .cvltr-view-layout-box .resume__section .controls-menu {
    right: -2rem;
  }

  .cvltr-view-layout-box .resume__section .resume__move {
    left: -2rem;
  }

  .resume-layout {
    min-height: inherit;
  }

  .cvltr-view-layout {
    min-height: inherit;
  }

  .modal {
    z-index: 9999;
  }
}

// .resume__controls a.resume__control-button:not(:last-child)::after,
// .resume__move a.resume__control-button:not(:last-child)::after {
// 	bottom: 0;
// 	content: '';
// 	border-right: 1px solid rgba(255,255,255,0.65);
// 	margin: auto;
// 	position: absolute;
// 	right: -2px;
// 	top: 0;
// 	height: calc(100% - 15px);
// 	z-index: 5;
// }

// Template Header
// .resume-layout {
// 	padding: 2rem 2.25rem;
// 	font-size: 0.875em;

// 	h1 {
// 		font-size: 3rem;
// 		text-transform: uppercase;
// 		margin-bottom: 1rem;
// 	}
// 	.sub-headline {
// 		h2 {
// 			font-size: 1.125rem;
// 			font-weight: bold;
// 			text-transform: uppercase;
// 		}
// 		.svg-icon {
// 			display: none;

// 			font-size: 1.43em;
// 			background: white;
// 			padding-bottom: 0.36em;
// 			padding-top: 2px;
// 		}
// 	}
// 	.personal-info.sub-headline {
// 		display: none;
// 	}
// 	.info-item .svg-icon {
// 		display: none;

// 		color: #42A2E0;
// 		margin-top: 3px;
// 		margin-right: 10px;
// 	}

// 	.info-item {
// 		display: flex;
// 		margin-bottom: 1em;
// 		.info-label {
// 			width: 12%;
// 			font-weight: bold;
// 			display: inline-block;
// 		}
// 		.info-text {
// 			width: 88%;
// 		}
// 	}
// 	.resume__summary {
//       ul {
//         margin: 0;
//         padding: 0;
//         list-style: none;
//       }
//     }

// 	.xp-item {
// 		overflow: hidden;
// 		margin-bottom: 1.6em;
// 		display: flex;
// 		.xp-date {
// 			width: 30%;
// 			font-weight: bold;
// 			text-align: right;
// 			padding-right: 1.6em;
// 			min-height: 1px;
// 			.xp-city {
//         display: block;
//     	}
// 		}
// 		.xp-job {
// 			width: 70%;
// 			border: 0;
// 			border-left: 1px solid lightgrey;
// 			padding-left: 1.5rem;
// 			.xp-detail {
// 				font-weight: 300;
// 				margin-top: 1rem;
// 				line-height: 1.6;
// 			}
// 		}
// 	}
// 	.wrap {
// 		display: flex;
// 		.left-col {
// 			width: 50%
// 		}
// 	}
// 	.extra {
// 		display: flex;
// 		align-items: center;
// 		justify-content: space-between;
// 		margin-bottom: 1em;
// 		.extra-details {
// 			position: relative;
// 			font-size: 0.75rem;
// 			text-align: left;
// 			font-style: italic;
// 			margin-left: auto;
// 			padding-right: 20px;
// 			.progress-starts {
//         display: block;
//     	}
// 			.progress-circle {
// 				display: none;
// 				.extra-details__bg {
// 					min-height: 22px;
// 				}
// 				.extra-details__bg > span {
// 					background-color: #E1E1E1;
// 					display: block;
// 					float: left;
// 					width: 1.5em;
// 					height: 1.5em;
// 					margin-right: 0.54em;
// 					border-radius: 50%;
// 				}
// 				.extra-details__progress {
// 					position: absolute;
// 					top: 0;
// 					left: 0;
// 					overflow: hidden;
// 					white-space: nowrap;
// 				}
// 				.extra-details__progress > span {
// 					background-color: #42A2E0;
// 					display: block;
// 					width: 1.5em;
// 					height: 1.5em;
// 					float: left;
// 					margin-right: 0.54em;
// 					border-radius: 50%;
// 				}
// 			}
// 			.progress-line {
// 				display: none;
// 				margin-top: 1em;
//         background-color: #D1D9E1;
//         width: 100%;
//         height: 5px;
//         position: relative;
//         margin-bottom: 5px;
// 				.extra-details__progress {
// 					background-color: #F68931;
// 					height: 5px;
// 					position: absolute;
// 					top: 0;
// 					left: 0;
// 					width: 100%;
// 				}
// 			}
// 		}
// 	}
// 	.resume-body {
// 		display: flex;
// 		h1 {
// 			font-size: 2rem;
// 		}
// 		.resume-aside {
// 			width: 33%;
// 			margin-right: 15px;
// 			padding-top: 1rem;
// 		}
// 		.resume-main {
// 			width: 77%;
// 			padding-top: 2rem;
// 		}
// 		.resume__section {
// 			button.resume__control-button:not(:last-child)::after,
// 			button.resume__control-button:not(:last-child)::after {
// 				display: none;
// 			}
// 			.resume__controls,
// 			.resume__move {
// 				left: -10px;
// 				right: -10px;
// 			}
// 			.resume__controls {
// 				.controls-menu {
// 					width: 27px;
// 					.resume__control-text {
// 						display: none;
// 					}
// 				}
// 			}
// 		}
// 	}

// 	// Temp section show hide
// 	.resume__experience {
// 		.exp-show {
// 			display: block;
// 		}
// 		.exp-hide {
// 			display: none;
// 		}
// 	}
// 	.resume__education {
// 		.edu-show {
// 			display: block;
// 		}
// 		.edu-hide {
// 			display: none;
// 		}
// 	}
// 	.txt-bold {
// 		font-weight: bold !important;
// 	}
// }

@media (max-width: 767.98px) { 
  .cvltr-wrapper .cvltr-view-layout {
    padding: 3em 1em 1em !important;
}
}