/*  */
.cvltr-red-color {
    color: #FF6600;
}

.cvltr-body-spacing {
    padding: 80px 20px 30px 70px;
}

/* .cvltr-wrapper-activebox {
    border: 0.3rem solid #a2d7ce;
    background-color: #f9fafb;
    padding-bottom: 10px;
    margin-bottom: 2rem;
} */

/* .cvltr-wrapper-activeheading {
    background-color: #92d9ce;
    color: #070707;
    padding: 0 1.7rem;
    height: 2.8rem;
} */

/* .cvltr-wrapper-img {
    margin-top: -1px;
}

.cvltr-wrapper-activecontent {
    padding: 0 3rem;
}

.cvltr-wrapper-content p {
    font-size: 15px;
}

.cvltr-wrapper-editimg {
    margin-right: 15px;
    margin-top: -15px;
}

.cvltr-wrapper-editimg img {
    width: 30px;
    height: 30px;
} */

/* .cvltr-wrapper-box {
    border: 0.1rem solid #cdcdcd;
    background-color: #fff;
    padding-bottom: 10px;
    margin-bottom: 2rem;
    cursor: pointer;
} */

/* .cvltr-wrapper-heading {
    background-color: #25a8e0 !important;
    color: #fff;
    padding: 0 1.7rem;
    display: inline-block;
    height: 2.8rem;
    margin-bottom: 12px;
} */

/* .cvltr-wrapper-content {
    padding: 0 3rem 11px 3rem;
}

.cvltr-list-wrapper {
    padding-right: 20px;
    padding-bottom: 40px;
} */

/* .cvltr-modal .modal-header {
    border-bottom: 0;
    padding: 3rem 4.5rem 0 4.5rem;
}

.cvltr-modal .modal-title {
    margin: 0 !important;
    font-size: 2.4rem;
    letter-spacing: -0.047rem;
} */

/* .cvltr-modal .modal-body {
    padding: 1.5rem 4.5rem 1.5rem;
} */

/* .cvltr-modalsection {
    border: 0.1rem solid #cdcdcd;
    border-radius: 0;
    padding: 0;
    background-color: #f9fafb;
}

.cvltr-sectionheader {
    padding: 1.5rem 2rem;
    width: 100%;
    height: 4rem;
}

.cvltr-sectionbody {
    padding: 1rem 2.5rem 2.2rem;
    margin-top: 2rem;
    height: 20rem;
}

.cvltr-modal .modal-footer {
    border-top: 0;
    padding: 1rem 4.5rem 3.5rem;
}

.cvltr-modal .btn-secondary {
    background-color: #ffffff;
    border-color: #000;
    color: #2e2e2e;
    font-weight: 500;
    font-size: 1.8rem;
    padding: 1.55rem 4.5rem;
    font-family: "Rubik", sans-serif;
    text-transform: uppercase;
} */


/* .cvltr-chip a {
    color: #2e2e2e;
    font-size: 2.0rem;
} */

/* .cvltr-chip img {
    width: 16px;
}

.cvltr-activechip {
    border: 0.1rem solid #3a3a3a;
    background-color: #3a3a3a;
}

.cvltr-activechip a {
    color: #fff;
} */

/* .cvltr-steps {
    margin-top: 15.5rem;
}

.cvltr-steps {
    position: relative;
    margin-left: 60px;
} */

.cvltr-content-scroll {
    max-height: 100px;
    overflow: auto;
}

.popup-delete-btn {
    color: #25a8e0;
    cursor: pointer;
    font-size: 1.3rem;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0 !important;
}

/* .range-slider__wrap .range-slider__tooltip .range-slider__tooltip__label {
    background-color: #f9fafb;
    width: 16rem;
    height: 11.4rem;
    border: 0.1rem solid #cdcdcd;
    color: #2e2e2e;
    font-size: 5.8rem;
    text-align: center;
    padding: 2rem 0 1rem 0;
    margin-bottom: 3.5rem;
    top: -83px;
    user-select: none;
    cursor: pointer;
    position: absolute;
}

.range-slider__wrap .range-slider__tooltip.range-slider__tooltip--top .range-slider__tooltip__caret::before {

    top: -20px;
    border-width: 2.4rem 3.4rem 0px;
    border-top-color: #f9fafb;
}

.range-slider__wrap .range-slider__tooltip .range-slider__tooltip__caret::before {
    left: -24px;
}

.cvltr-year-text {
    font-size: 2rem;
    margin-top: 0.9rem;
    text-transform: uppercase;
    display: block;
}

.cvltr-step {
    font-size: 2rem;
    cursor: pointer;
    color: silver;
}

.cvltr-activestep {
    color: #000;
}

.range-slider__wrap {
    height: calc(1.5em + -0.15rem + 2px);
} */

/* .cvltr-year-text::after {
    content: "";
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAAAXCAIAAAAnV54yAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHeSURBVFhH7dfZcoJAEEBR/v/nFARFiEQJ+yaLyKaSNj2KoWIUhaBWbs2TyzBHqlqlsrx4yUUt/aD20AusMIoojuNYlrNtp/bcky7XW06nU4ZhqLIsfd/neZ5lWcu2a697omXZDtwkyHGc3W63h2EVz3omXprlumGMRiM4PBAIpiwrGBYEAfLMh+claaqoKk3ToihGUUQAh+ow7IRn1bZ7hBWvk4UsD4dDSZKSJCGH/t7PMAx5cJdN04I7Xtu9lxWtYkl6B5Isy1mWkYP+1G8w7MgzTLNHXhCEovgGJFVVi6IghzvfZRjWI891PZ7fT3DDMLbbLTnQpa6FYRXP6JwH+8MA+/qaZW3bhglODnFdzWAY8uAjhDnbBQ/21PX9BJ9MJp7nkas27BYYVvH01njrJP1QFJjggiCEYUiudFO3wzC4PP6E0e7jxfF6vlgMBoPZbBbHMdn9ju6FYcij9zy9KS+MVjNJAtJ8Pk/TlOx4d+3AMMKjGU27iucHoSCKMMEVRcnznOzSUm3CsAOPVjXtHM9x3QnPw2t0Xd9sNuSdrdY+DDvlJWmGHnDCjxiW42DiWZbVdII3qisYduTBrFNUDWbMeDx2XbdTEtYtDEMedPq3ouv+AtZL/7Dnqiw/AbXD+ht1BjznAAAAAElFTkSuQmCC');
    content: "";
    display: block;
    width: 9.2rem;
    height: 2.5rem;
    position: absolute;
    left: 3.5rem;
    bottom: -2.29rem;

} */

.range-slider__wrap .range-slider__tooltip .range-slider__tooltip__caret {
    display: none;
}

@media only screen and (min-device-width: 1000px) and (max-device-width: 1100px) and (orientation:portrait) {
    .range-slider__wrap .range-slider__tooltip .range-slider__tooltip__label {     top: -99px; }
    .cvltr-year-text::after { width: 7.2rem;
      left: 4.5rem;}
   }

@media only screen and (min-device-width: 768px) and (max-device-width: 992px) and (orientation:portrait) {
    .cvltr-steps {
        position: relative;
        margin-left: 47px;
        margin-right: 35px;
    }
    
    .range-slider__wrap .range-slider__tooltip .range-slider__tooltip__label {     top: -99px; }

    .cvltr-year-text::after {
      width: 7.2rem;
      height: 2.5rem;
      position: absolute;
      left: 4.5rem;
      bottom: -2.29rem;
  }
}


@media (max-width: 767.98px) {

    /* .range-slider__wrap .range-slider__tooltip .range-slider__tooltip__label {
        width: 14rem;
        height: 10.4rem;
    }

    .range-slider__wrap {
        height: calc(1.5em + -1.15rem + 2px);
    }

    .cvltr-year-text::after {
        width: 7.2rem;
    }

    .cvltr-body-spacing {
        padding: 20px;
    } */

    /* .cvltr-modal .modal-header {
        padding: 8px;
    }

    .cvltr-modal .modal-body {
        padding: 8px;
    }

    .cvltr-modal .modal-footer {
        padding: 8px;
    }

    .cvltr-modal .btn-secondary {
        padding: 1.3rem 4.2rem;
        font-size: 1.5rem;
    } */

}


@media screen and (min-width:992px) {
    /* .scroll-sidebar {
        max-height: calc(calc(var(--vh, 1vh) * 100) - 60px - 60px - 90px);
        min-height: calc(calc(var(--vh, 1vh) * 100) - 60px - 60px - 90px);
        margin-bottom: 0px !important;
    } */

    .modal-lg {
        --bs-modal-width: 700px;
    }

}