@import "../../assets/scss/variables";

h1 span {
  font-weight: $font-weight-normal;
}

.setting-page-headline {
  padding-top: 2.75rem;

  @media (max-width: 767px) {
    padding-top: 0;
    font-size: 0.987rem;
  }
}

.user-settings {
  // margin: 50px 180px 100px;

  // @media (max-width: 991px) {
  //   margin: 45px 25px 100px;
  // }

  // @media (max-width: 767px) {
  //   margin: 50px 20px 100px;
  // }
  .alignment-container {
    @media (max-width: 767px) {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  li {
    margin-bottom: 0;
  }

  .nav-tabs .nav-link {
    padding: 1em 2em;
    min-width: 275px;
    border: 1px #c1c0c5 solid;
    border-radius: 0px;
    color: #3e3e3e;

    @media (max-width: 991px) {}

    @media (max-width: 767px) {
      padding: 0.75em 1em;
      min-width: auto;
    }

    @media (max-width: 575px) {
      padding: 0.5em 0.5em;
    }

    // min-width: 280px;
    // border: 1px #c1c0c5 solid;
    // border-radius: 0px;
    // color: #3e3e3e;
    // @media (max-width: 767px) {
    //   min-width: 0px;
    //   border: 1px #c1c0c5 solid;
    //   border-radius: 0px;
    // }

    // @media (max-width: 991px) {
    //   height: 60px;
    //   width: 170px;
    //   min-width: 0px;
    //   color: #3e3e3e;
    //   border: 1px #c1c0c5 solid;
    //   border-radius: 0px;
    // }
    // @media (max-width: 767px) {
    //   height: 40px;
    //   width: 120px;
    //   min-width: 0px;
    //   color: #3e3e3e;
    //   border: 1px #c1c0c5 solid;
    //   border-radius: 0px;
    // }
  }

  // .nav-tabs .nav-link.active,
  // .nav-tabs .nav-item.show .nav-link {
  //   min-width: 280px;

  //   @media (max-width: 767px) {
  //     min-width: 0px;
  //   }

  //   @media (max-width: 991px) {
  //     min-width: 0px;
  //     color: black;
  //   }
  // }

  .nav-tabs .nav-link.active {
    color: $color-white;
    background-color: $secondary-color;
  }

  // .tab-overview {}

  .tab-subscription {
    .list-group-item {
      padding-left: 0;
    }

    dl {
      margin: 0;
      padding: 1rem 0;

      dt {
        margin: 0;
        padding: 0;
        font-size: 1.6rem;
      }

      dd {
        font-size: 1.2rem;
        padding-left: 0;
        margin: 0;
        padding: 0;
      }
    }
  }

  .tab-footer {
    margin-bottom: 3rem;

    @media (max-width: 767px) {
      text-align: center;

      .btn-primary {
        padding: 1.3rem 2.2rem;
        font-size: 1.75rem;
      }
    }
  }

  .fullname-icon {
    position: relative;

    &::before {
      content: "\f007";
      font-family: "FontAwesome";
      position: absolute;
      color: #EBEBEB;
      left: 20px;
      bottom: 1rem;
      width: 20px;

    }


  }

  .email-icon {
    position: relative;

    &::before {
      content: '\f0e0';
      font-family: "FontAwesome";
      position: absolute;
      color: #EBEBEB;
      left: 20px;
      bottom: 1rem;
      width: 20px;

    }
  }

  .password-icon {
    position: relative;

    &::before {
      content: '\f023';
      font-family: "FontAwesome";
      position: absolute;
      color: #EBEBEB;
      left: 20px;
      bottom: 1rem;
      width: 20px;

    }
  }

}

#changePass {
  margin-bottom: 10px;

  .toggle-btn {
    display: none;
    color: $secondary-color;
  }

  &.section-hide {
    .toggle-btn {
      display: block;
    }

    .change-password {
      display: none;
    }
  }
}

.user-setting-edit-btn {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  margin-bottom: 2rem;

  .is-block.btn-primary {
    width: 100%;
    min-width: auto;
    margin-bottom: 1em;
    padding: 1.55rem 1rem;
  }
}

.plan-container {
  text-align: center;
  margin: auto;
  border-radius: 10px;

  @media (min-width: 1024px) {
    width: 32%;
  }

  @media (max-width: 767px) {
    padding-bottom: 20px;
  }

  h5 {
    font-weight: bold;
  }
}

.plan-cancel-container {
  background-color: #f5f6f6;
  padding: 20px 19px;
  max-width: 350px;
  margin: 0 auto;
}

.plan-cancel-container p {
  font-size: inherit;
}

// .plan-buynow-btn {
//   display: none;
// }

.cancel-subscription {
  color: $secondary-color  !important;
  text-decoration: none;
  margin-left: -10px;
}

.cancel-subscription:hover {
  text-decoration: underline;
}