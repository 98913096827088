 .checkout {
     .container {
         max-width: 1080px;
         padding: 0 15px 20px;
     }

     .cardnumber-error {
         color: red;
         font-weight: 600;
         font-size: 14px;
         margin-bottom: 10px;
     }

     .flagger:not(.checkbox-error) {
         visibility: hidden !important;
     }

     .left-side-container {
         padding-left: 20px;
     }



     .card-details {
         border: 1px solid #ee7856;
         border-radius: 3px;
         box-shadow: 0 2px 35px rgb(0 0 0 / 10%);
         padding: 15px 20px 10px;
         max-width: 510px;
         color: #545454;

         @media (max-width: 991px) {
             max-width: 100%;
         }

         @media (max-width: 767px) {
             padding: 25px 25px 10px;
         }

         @media (max-width: 459px) {
             border-radius: 0px;
             box-shadow: none;
             padding: 13px;
         }

         @media screen and (min-width: 1200px) and (max-width: 1599px) {
             max-width: 633px;

             margin-bottom: 10px;
         }

         h1 {
             font-size: 22px;
             font-family: "Rubik", sans-serif;
             font-weight: 800;
             text-transform: capitalize;
             margin-bottom: 1.65rem;

             @media (max-width: 767px) {
                 font-size: 1.6rem;
                 margin-bottom: 1.5rem;
             }
         }


         .subheading-price {
             font-weight: 700;
             font-size: 22px;
             margin-top: -4px;

             @media (max-width: 767px) {
                 font-size: 1.6rem;
                 margin-bottom: 1.5rem;
             }

         }

         .fa-check::before {
             content: "";
         }


         .checkout-checkbox {

             display: flex;

             input[type="checkbox"] {
                 position: relative;
                 width: 14px;
                 height: 14px;
                 top: 5px;
                 margin-right: 10px;
                 padding: 0px;
                 overflow: hidden;
                 clip: rect(0, 0, 0, 0);
                 border: 0;

                 @media (max-width:767px) {
                     top: 12px
                 }
             }

             input[type="checkbox"]+label {
                 display: inline-block;
                 cursor: pointer;
                 -webkit-user-select: none;
                 -moz-user-select: none;
                 -ms-user-select: none;
                 user-select: none;
                 padding-right: 0.5em;
                 text-transform: initial;
                 letter-spacing: initial;
             }
         }

         .total-divider {

             border-top: 1px solid #ee7856;
             margin-right: -20px;
             margin-left: -20px;

             @media (max-width:767px) {

                 margin-right: -13px;
                 margin-left: -13px;

             }



         }


     }



     .mainHeading {
         padding: 15px 10px 35px;
         text-transform: initial;

         font-size: 27px;
         color: #424a5b;
         font-family: "Poppins", sans-serif;
         font-weight: 800;
         text-align: center;

         @media (max-width:767px) {
             font-size: 18px;
             padding: 0px;
         }
     }


     .swiper-button {
         position: relative;
         margin-bottom: 20px;

         //  @media (max-width: 991px) {
         //      padding-top: 26%;

         //  }

         //  @media (max-width: 767px) {
         //      padding-top: 62%;

         //  }

         //  @media (max-width:367px) {
         //      padding-top: 97%;
         //  }

         .swiper-button-prev {
             width: 39px;
             left: 0;
         }

         .swiper-button-next {
             width: 39px;
             right: 0;
         }



     }

     .swiper-pagination {
         position: relative;
         top: 0;
         text-align: center;
         transition: .3s opacity;
         transform: translate3d(0, 0, 0);
         z-index: 10;

         @media(max-width:767px) {
             top: 5px;

         }

         @media(max-width:375px) {
             top: 17px;

         }


     }




 }