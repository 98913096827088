@import "../../assets/scss/variables";

.intro {
    // padding: 56px 0;
    margin-bottom: 8rem;
    padding-top: 2rem;


    @media (max-width: 992px) {
        padding: 25px 0;
    }

    .headline-group {
        margin-bottom: 3rem;
        justify-content: center;
        padding: 0 1.2rem;
    }

    .intro-wrap {
        // margin: 70px auto 40px;
        margin: 0 auto;
        padding: 3rem 0 5rem;
        max-width: 1160px;

        @media(max-width:767px) {
            margin: 0;
            margin-top: -24px;
        }

        .builder-step {
            position: relative;
            height: 100%;

            &:after {
                border-bottom: 1px solid #c1c0c5;
                content: '';
                top: 10rem;
                position: absolute;
                right: -50%;
                width: 100%;
                z-index: -1;

                @media (max-width: 767px) {
                    border: 0;
                }
            }

            // :not(:last-child)
            &--number {
                align-self: center;
                width: 40px;
                font-weight: $font-weight-bold;
                color: $secondary-color;
                background: $color-white;
                font-size: 2rem;
                margin: -0.6em auto 0;
                position: absolute;
                left: 0;
                right: 0;
                line-height: normal;
            }

            &--icon {
                margin: 0 auto;
                width: 20rem;
                height: 20rem;
                line-height: 20rem;
                background: $color-white;
                border: 1px solid #c1c0c5;
                border-radius: 100%;

                @media (max-width: 767px) {
                    width: 100px;
                    height: 100px;
                    margin-top: 35px;
                    line-height: 95px;
                }

                &.step-1 {
                    img {
                        max-width: 45px;

                        @media (max-width: 767px) {
                            max-width: 25px;
                        }
                    }
                }

                &.step-2 {
                    img {
                        max-width: 70px;

                        @media (max-width: 767px) {
                            max-width: 45px;
                        }
                    }
                }

                &.step-3 {
                    img {
                        max-width: 50px;

                        @media (max-width: 767px) {
                            max-width: 30px;
                        }
                    }
                }
            }

            &--text {
                display: block;
                margin: 0 auto;
                padding: 15px 2em;
                font-size: 1.8rem;
                line-height: 1.2;
                font-weight: 400;
                width: calc(100% - 34px);

                @media (max-width: 1199px) {
                    padding: 15px 10px;
                    width: 100%;
                }

                @media (max-width:991px) {
                    display: block;
                    margin: 0 auto;
                    padding: 15px 2em;
                    font-size: 1.8rem;
                    line-height: 1.2;
                    font-weight: $font-weight-normal;
                    width: calc(100% - -11px);
                }


                @media(max-width:767px) {
                    font-size: 1.3rem;
                    max-width: 223px;
                }
            }
        }
    }

    .intro-bottom {
        -webkit-justify-content: center;
        justify-content: center;
        padding: 1rem;

        @media (max-width:767px) {
            padding: 0;
            padding-top: 10px;
            margin-left: 4%;
        }

        &--terms {
            text-align: center;
            width: 30rem;
            margin: 0 auto;
            line-height: 1;
            padding-top: 5px;

            small {
                font-size: 78%;

                @media(max-width:767px) {
                    font-size: 60%;
                }

                a {
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }


            @media (max-width:991px) {
                max-width: 300px;
                font-size: 21px;
            }
        }
    }
}

.intro .intro-wrap [class*="col-"]:last-child .builder-step:after {
    display: none;
}