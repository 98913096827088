@import "../../assets/scss/variables";

.page-section {
  padding-bottom: 2rem;
  padding-top: 2rem;

  @media (max-width: 992px) {
    padding: 25px 0;
    margin-bottom: 0;
  }

  .headline-group {
    margin-bottom: 3rem;
    justify-content: center;
    padding: 0 1.2rem;
  }

  .page-section-wrap {
    // margin: 70px auto 40px;
    margin: 0 auto;
    padding: 3rem 0 3rem;
    max-width: 1024px;

    &.drive {
      max-width: 900px;
    }

    @media (max-width: 767px) {
      margin: 0;
      margin-top: -24px;
    }
  }

  // .page-section-wrap div {
  //   cursor: pointer;

  // }
}