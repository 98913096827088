.cvltr-load-more{
    background-color: var(--secondary-cvltr-blue-color) !important;
    color: var(--primary-cvltr-light-color) !important;
}

.cvltr-cursor {    cursor: pointer;}

.cvltr-border-start {
    border-left: 1px solid var(--primary-cvltr-ltgray-color) !important;
}

.cvltr-tooltiplist {     list-style: none;     padding-left: 0;     margin-bottom: 0;
}

.popover-header {     background-color: var(--primary-cvltr-white-color);     font-size: 1.8rem;
    font-weight: 700;
    padding: 1.3rem 1.3rem 2rem 2rem; }

    .popover-body {        padding: 1.5rem 0 0 0;     font-size: 1.6rem;  }

    /* .popover {     --bs-popover-max-width: 36%;     padding: 1.5rem;     inset: 0px auto auto -1.6% !important;  } */

    .popover-arrow {     left: 21px !important; }


    @media (max-width: 767.98px) { 
        /* .popover {
            --bs-popover-max-width: 88%;
            padding: 1.5rem;
            inset: 0px auto auto -6.6% !important;} */

            .popover-header {     padding: 1.3rem 0 2rem 0; }
            .popover-body {     max-height: 33rem;
                overflow: scroll; }
    }
