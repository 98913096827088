.summary-header {
    border-bottom: 1px solid #c1c0c5;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    font-family: "Rubik", sans-serif;
    border-color: transparent;
}


.summary-save-btn {
    margin-right: -8px;

    @media (max-width: 992px) {
        margin-right: -13px !important;
    }

    @media (max-width:767px) {
        width: 40%;
        margin-left: 20% !important;
    }
}

.summary-container {
    margin-bottom: 30px;
}