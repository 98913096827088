@import "./variables";

.box__header {
  @media (min-width: 768px) {
    margin-bottom: 1rem;
  }
}

.heading-style {
  margin-top: 20px;

  @media (max-width: 991px) {
    padding-top: 7px;
  }
}

.builder-info-tips {
  li {
    display: flex;
    position: relative;
    list-style: none;
    margin-bottom: 1rem;
  }
}

.tips-info-image {
  padding-left: 60px;

  @media (max-width: 991px) {
    display: none;
  }
}

.tip-content {
  padding: 2.4rem 16rem;

  @media (max-width: 991px) {
    padding: 2.4rem 1.2rem 0;
    margin-left: -3.4rem;
    margin-right: -1.4rem;
    margin-bottom: 2.4rem;
    position: relative;
  }

  @media (max-width: 767px) {
    padding: 0 10px;
  }
}

.experienceTips_backbutton {
  @media (max-width: 767px) {
    margin-left: 12px;
    font-size: 1rem;
  }
}

.experienceTips_nextbutton {
  @media (max-width: 991px) {
    font-size: 1.8rem;
    padding: 16.5px 63px !important;
  }

  @media (max-width: 399px) {
    margin-left: 26%;
    font-size: 1rem;
  }
}

.tips-btn-container {
  display: flex;
  justify-content: space-between;
}

.writing-tips {
  li {
    list-style: none;
    margin-bottom: 2rem;
    padding-left: 27px;
    position: relative;

    &::before {
      position: absolute;
      left: 0;
      color: $secondary-color;
      content: '\f00c';
      font-family: 'FontAwesome';
      padding-right: 5px;
    }
  }
}

.writing-tips li::before,
.hero__list li::before {
  color: $secondary-color;
  content: "\f00c";

  font-family: "FontAwesome";
  padding-right: 0.5rem;
}

.writing-tips-parent {
  .writing-tips {
    padding: 0;

    li {
      display: flex;

      position: relative;
      list-style: none;
      margin-bottom: 0.75rem;
      font-size: 1.6rem;
    }
  }

  .writing-tips-heading {
    font-size: 1.8rem;
    margin-bottom: 0;
    padding: 0.8em 2em 0.8em .5em;
    position: relative;
    font-weight: bold;
  }
}

.writting-tip-btn {
  color: $secondary-color;

}

#popover-trigger-click-root-close {
  max-width: 512px;
  padding: 1.5rem;
  right: 0;
}

@media screen and (max-width: 991px) {
  .writting-tip-btn {
    display: none;
  }
}