#change-template {
    .sb-template__change {
        margin-left: -1.2rem;
        margin-right: -1.2rem;
        margin-bottom: -1.2rem !important;
    }
    .resume-sidebox__inner {
        max-height: 406px;
        overflow: auto;
    }
    .sb-template__change-inner {
        display: flex;
        flex-wrap: wrap;
        padding-top: 1rem;
        .sb-template__template {
            flex-basis: 50%;
            margin-top: 1rem;
            margin-bottom: 1rem;
            text-align: center;
            z-index: 99;
            img {
                max-width: 75%;
                display: block;
                margin: auto;
                outline: 2px solid #c1c0c5;
            }
            span {
                font-size: 70%;
            }
        }
        .sb-template__template.is-selected {
            position: relative;
        }
        .sb-template__template.is-selected:after,
        .sb-template__template.is-selected:before {
            content: "\f00c";
            font-family: "FontAwesome";
            position: absolute;
            top: 30%;
            left: 0;
            right: 0;
            text-align: center;
            color: #fff;
            font-size: 130%;
        }
        .sb-template__template.is-selected:before {
            background: rgba(46, 190, 110, 0.7);
            border-radius: 50px;
            content: '';
            margin: auto;
            height: 1.5em;
            width: 1.5em;
        }
        .sb-template__template.is-selected img {
            outline: 2px solid #2ebe6e;
        }
    }
    .sb-template__change-inner img:hover {
        outline: 2px solid #2ebe6e;
    }
}