@import "../../assets/scss/variables";
// button,
// input,
// optgroup,
// select,
// textarea {
// color: inherit;
// font: inherit;
// margin: 0;
// }

// input {
// line-height: normal;
// }

// input:not([type="submit"]),
// select,
// textarea,
// .input-file+label,
// .select2-container {
// width: 100%;
// max-width: 100%;
// display: block;
// color: #222;
// border: 1px solid #c1c0c5;
// border-radius: 4px;
// font-size: 14px;
// padding: 0.6em 1em;
// transition: background 0.2s ease-out, border 0.2s ease-out;
// background-image: none;
// background-color: white !important;
// margin-bottom: 0.2em;
// @media (max-width:992px)
// {
// font-size: 20px;
// }
// }
// .tooltip-wrapper > input {
// margin-bottom: 0;
// font-size: 14px;
// @media (max-width:992px) {
// font-size: 16px;
// }
// }
// input:last-child:not([type="submit"]),
// select:last-child,
// textarea:last-child,
// .input-file+label:last-child,
// .select2-container:last-child {
// margin-bottom: 0;
// font-size: 14px;
// }

// input:last-child:not([type="submit"]),
// select:last-child,
// textarea:last-child,
// .input-file+label:last-child,
// .select2-container:last-child {
// margin-bottom: 0;
// @media (max-width:992px)
// {
// font-size: 17px;
// }
// }


.container .upload-image-btn {
  display: inherit;
  margin-top: -12px;
  display: flex;
  align-items: center;
  img {
    width: 100px;
    height: 100px;
  }

  .upload-btn {
    margin-left: 15px;
    font-size: 14px;
    padding: 1rem 2rem;
    min-width: 180px;

    @media (max-width: 767px) {
      min-width: 0;
    }
  }
  .photo-placeholder {
    border: 1px #ccc solid;
    position: relative;
    width: 100px;
    height: 100px;
    display: block;
    color: #999;
    .spinner-border {
      position: absolute;
      margin: 0 auto;
      left: 43px;
      top: 43px;
      z-index: 9;
    }
  }
}

.temp-not-selected {
  display: flex;
  align-items: center;
}

.temp-not-selected .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border: 1px solid gray;
  font-size: 40px;
  margin-right: 10px;
  color: #b6b6b6;
}

.temp-not-selected-text {
  width: 250px;

  @media (max-width: 675px) {
    font-size: 15px;
  }
}

.box__header {
  margin-left: 47px;

  @media (max-width: 991px) {
    margin-left: 14px;
  }
}

.box__content {
  margin-left: 12px;
  margin-right: 12px;

  @media (max-width: 991px) {
    margin-left: 0px;
    margin-right: 0px;
  }
}