@import '../../../assets/scss/variables';

.navbar-btn {
    // padding: 0.35rem 1rem;
    // border: 1px solid #fe8c26;
    border-radius: 4px;
    padding: 0;
    border: none;
    color: #fe8c26;
    font-size: 1.6rem;
    font-family: "Rubik", sans-serif;
    text-decoration: none;

    @media (max-width:991px) {
        border: none;
        background-color: white;
        border: none !important;
        box-shadow: none !important;

        &:hover,
        &:focus {
            background: none;
        }
    }

    .dropdown-btn-hide {
        padding: 0.35rem 1rem;
        border: 1px solid #fe8c26;
        border-radius: 4px;
        display: inline-block;

        &:hover {
            border-radius: 4px;
        }

        @media (max-width:991px) {
            display: none;
        }
    }

    &.dropdown {
        background-color: $color-white;
        border: none !important;
        box-shadow: none !important;

        @media (max-width:991px) {
            background-color: $color-white;
            border: none !important;
            box-shadow: none !important;
        }

    }
}

// .form-inline {
//     @media (max-width:991px) {
//         .dropdown-menu {
//             /* position: absolute; */
//             inset: 0px 0px auto auto;
//             top: -60px !important;
//             bottom: 0 !important;
//             padding: 10px 10px;
//             width: 20rem;
//             background: black;
//             position: fixed !important;
//             transform: translate3d(-0.5px, 54px, 0px);
//             color: white;
//             font-size: 1.6rem;

//             &::before {
//                 position: absolute;
//                 left: -45px;
//                 top: 16px;
//                 content: "\f00d";
//                 font-family: 'FontAwesome';
//                 width: 20px;
//                 height: 20px;
//                 background-color: #2ebe6e;
//             }
//         }
//     }
// }

.account-menu {
    .navbar-btn .dropdown-btn-hide {
        padding-right: 26px;
    }

    .dropdown-toggle::after {
        margin-left: -10px;
        position: absolute;
        right: 10px;
        top: 13px;
        content: " ";
    }

    @media (max-width: 991px) {
        .dropdown-toggle {
            &::after {
                content: none;
            }
        }
    }

    &:after {
        // content: none;
        text-decoration: none !important;
    }

    &:hover {
        color: $secondary-color;
        background-color: $color-white ;
    }
}

.sign-menu {
    .dropdown-menu {
        @media (min-width: 992px) {
            display: none;
        }
    }
}



.navbar-menu {
    .dropdown-menu {
        min-width: 13em;
        line-height: 2.8rem;
        font-size: 1.6rem;
        padding: 10px 10px;
        margin-top: 10px;
        border: 0px;
        border-radius: 7px;
        box-shadow: 0 2px 30px rgba(91, 69, 69, 0.15);
        transform: translate3d(0px, 0px, 0px) !important;
        top: 30px !important;
        right: 0 !important;
        left: auto !important;

        @media (max-width: 991px) {
            position: fixed !important;
            top: 0 !important;
            right: 0 !important;
            bottom: 0 !important;
            padding: 0;
            margin-top: 0;
            border-radius: 0;
            background: #222;
            height: 100vh;
            width: 26rem;
            transform: translate3d(0px, 0px, 0px) !important;

            .dropdown-item {
                color: $color-white;
                padding: 0.5em 1em;

                &:hover,
                &:focus {
                    background: none;
                }

                &:nth-child(odd) {
                    background: rgba(255, 255, 255, 0.05);
                }
            }
        }

        .dropdown-item {

            &:hover,
            &:focus {
                background: none;
                text-decoration: none;
                color: $secondary-color;
            }
        }

        .btn-cross {
            display: none;

            @media (max-width: 991px) {
                position: absolute;
                left: -55px;
                display: block;
                font-size: 30px;
                width: 55px;
                height: 55px;
                margin: 0;
                padding: 0;
                background-color: $color-white;

                img {
                    max-width: 19px;
                }
            }
        }


        .divider {
            display: none;

            @media (max-width: 991px) {
                display: block;
                background-color: #c1c0c5;
                opacity: 1;
                // display: block;
                margin-top: 0;
            }
        }
    }

    .bars-hidden-cls {
        display: none;

        @media (max-width:991px) {
            color: $secondary-color;
            display: block;
            font-size: 30px;
        }

        i {
            margin: 0;
            padding: 0;
        }
    }

    .btn-link {
        &:focus {
            box-shadow: none;
        }
    }

}

.hide-arrow {
    .dropdown-toggle {
        &::after {
            content: none;
        }
    }
}



.btn-outline-primary :hover {
    @media (max-width:991px) {
        background-color: $color-white  !important;
    }
}

.cross-icon-navbar {

    position: absolute;
    color: $secondary-color;
    margin-left: -50px;

}

.close-icon-style {
    color: $secondary-color;
}