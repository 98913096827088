.cvltr-layout.cvltr-5 h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Open Sans TTF",sans-serif;
  text-transform: uppercase;
}
.cvltr-layout.cvltr-5 {
  font-family: "Open Sans TTF",sans-serif;
}
.cvltr-layout.cvltr-5 .cvltr-headingname {
  background-color: #0694c4;
  color: #ffffff;
  padding-top: 30px;
  padding-bottom: 15px;
  padding-left: 10px;
}
.cvltr-layout.cvltr-5 .cvltr-information {
  border-bottom: 2px solid #dddddd;
  margin-top: 20px;
  margin-bottom: 15px;
}
.cvltr-layout.cvltr-5 .cvltr-d-flex {
  display: flex;
  /* align-items: center; */
}
.cvltr-layout.cvltr-5 .cvltr-rightspacing {
  min-width: 65px;
}
.cvltr-layout.cvltr-5 .cvltr-companyinfo p {
  margin-bottom: 5px;
  margin-top: 0;
}
.cvltr-layout.cvltr-5 .cvltr-addressinfo p {
  margin-top: 0;
  margin-bottom: 0;
}
.cvltr-layout.cvltr-5 .cvltr-addressinfo {
  border-bottom: 2px solid #dddddd;
}
.cvltr-layout.cvltr-5 .cvltr-mt30 {
  margin-top: 30px;
}
.cvltr-layout.cvltr-5 .cvltr-mb30 {
  margin-bottom: 30px;
}
/* .cvltr-layout.cvltr-5 .cvltr-mb0 {
  margin-bottom: 0px;
} */
.cvltr-layout.cvltr-5 .cvltr-mb10 {
  margin-bottom: 10px;
}
.cvltr-layout.cvltr-5 .cvltr-subheading {
  display: none;
}
.cvltr-layout.cvltr-5 .cvltr-img {
  display: none;
}
.cvltr-layout.cvltr-5 .cvltr-emaildisplay {
  display: none;
}
.cvltr-layout.cvltr-5 .cvltr-lable b {
  min-width: 75px;
  display: inline-block;
}
.cvltr-layout.cvltr-5 .cvltr-solid-border {
  border-bottom: 2px solid #dddddd;
}
.cvltr-layout.cvltr-5 .cvltr-dother-none {
  display: none;
}
.cvltr-layout.cvltr-5 .show-comma {
  display: none;
}
/* .cvltr-layout.cvltr-5 .cvltrflexwrap { flex-wrap: wrap !important; } */

@media (max-width: 767.98px) { 
  .cvltr-layout.cvltr-5 .cvltr-lable b {
    min-width: 75px;}
 } 