@import "../../assets/scss/variables";

.template-container {
  margin-left: 40px;
  margin-top: 50px;
  margin-right: 40px;

  @media (max-width: 768px) {
    margin-top: 10px;
  }

  @media (max-width: 991px) {
    margin-left: 0px;
    margin-top: 10px;
    margin-right: 0px;
  }
}

.templates {
  @media (max-width: 991px) {
    padding: 0px 0px 40px;
  }

  .templates-content {
    .builder-steps {
      display: flex;
      display: -webkit-flex;
      padding: 30px 0 40px;

      .builder-step {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        height: 100%;

        &:after {
          border-bottom: 1px solid #c1c0c5;
          content: "";
          top: calc(50% - 40px);
          position: absolute;
          right: -50%;
          width: 100%;
          z-index: -1;
        }

        // :not(:last-child)
        &--number {
          position: absolute;
          left: 0;
          right: 0;
          width: 40px;
          font-size: 1rem;
          color: $secondary-color;
          background: $color-white;
          margin: -0.6em auto 0;
        }

        &--icon {
          width: 160px;
          height: 160px;
          background: $color-white;
          border: 1px solid #c1c0c5;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          &.step-1 {
            img {
              max-width: 45px;
            }
          }

          &.step-2 {
            img {
              max-width: 70px;
            }
          }

          &.step-3 {
            img {
              max-width: 50px;
            }
          }
        }

        &--text {
          margin: 15px 0;
          font-size: 0.9375rem;
          padding: 0 2em;
          font-weight: 400;
          width: calc(100% - 80px);
        }
      }
    }
  }

  .templates-bottom {
    justify-content: center;
    -webkit-justify-content: center;
    padding: 2.4rem;

    &--btn {
      margin-bottom: 20px;
    }

    &--terms {
      small {
        margin: 0 auto;
        display: block;
        max-width: 280px;
        font-size: 0.75rem;

        a {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.templates .templates-content .builder-steps [class*="col-"]:last-child .builder-step:after {
  display: none;
}

.template-file__image img {
  display: block;
}

.template-file__image img {
  width: 100%;
}

.template-file__image {
  display: inline-block;
  transition: 0.3s;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.template-file:first-child .template-file__image {
  transform-origin: left center;
}

.template-file__image:hover {
  position: relative;
  z-index: 1;
}

@media (min-width: 992px) {
  .template-file__image:hover {
    transform: scale(1.5);
    box-shadow: 0 3px 5px rgb(0 0 0 / 30%);
  }
}

.template-file:first-child .template-file__image {
  transform-origin: left center;
}

.template-file__image:hover .btn-primary {
  opacity: 1;
  transition: 0.2s 0.3s opacity;
}

.template-file__image .btn-primary {
  position: absolute;
  top: 45%;
  left: 3rem;
  right: 3rem;
  opacity: 0;
  text-transform: uppercase;
  // font-size: 1.8rem;
}

.template-file {
  margin-bottom: 2rem;
  text-align: center;
}

.template-name {
  font-size: 2rem;
  font-weight: bold;
}

.template-skip-btn {
  padding: 11px 35px;
  font-size: 1.8rem;
  text-transform: uppercase;
  color: $secondary-color;
  border-color: $secondary-color;
}

.template-skip-btn:hover {
  background-color: white !important;
  border-color: $primary-color-dark  !important;
  color: $primary-color-dark  !important;
}