@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&family=Dynalight&family=Mrs+Saint+Delafield&display=swap');


.font-dancing {
    font-family: 'Dancing Script', cursive;
}

.font-dynalight {
    font-family: 'Dynalight', cursive;
}

.font-saint {
    font-family: 'Mrs Saint Delafield', cursive;
}

.font-24 {
    font-size: 24px;
}

.font-10 {
    font-size: 10px;
}

.font-20 {
    font-size: 18px;
}

.cvltr-ln-height {
    line-height: 24px;
}

.cvltr-ic20 {
    width: 20px;
    height: 20px;
}

.cvltr-ic50 {
    width: 50px;
    height: 50px;
}

.cvltr-icSign {
    width: 50%;
    height: 15rem;
    background-color: var(--primary-cvltr-gray-color);
    color: var(--primary-cvltr-ltgray-color);
}

.sign-pad .nav-tabs {
    border-bottom: 0;
}

.sign-pad .nav-tabs .nav-link {
    background: var(--secondary-cvltr-darkblue-color);
    color: var(--primary-cvltr-white-color);
    border-radius: 5px;
}

.sign-pad .nav-item {
    margin-right: 10px;
    font-size: 12px;
}

.sign-pad .nav-tabs .nav-link.active,
.sign-pad .nav-tabs .nav-item.show .nav-link {
    background: var(--secondary-cvltr-ltblue-color);
    color: var(--primary-cvltr-white-color);
    border-radius: 5px;
}

/* .cvltr-signature-spacing {
    padding: 80px 20px 20px 20px;
} */

.cvltr-signaturename .form-control {
    height: 30px;
    padding: 7px;
    border-radius: 0;
    font-size: 13px;
    margin-bottom: 15px;
}

.form-check-input[type=radio] {
    box-shadow: none;
}

.cvltr-signatureform .form-check {
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #ffffff;
    padding-left: 2.5em;
}

.cvltr-signatureform .form-check.active {
    background-color: #E7F5FF;
}

.cvltr-radioheight {
    height: 155px;
    overflow: scroll;
    padding-left: 0;
    border-top: 1px #ccc solid;
    border-bottom: 1px #ccc solid;
}

.css-1u9des2-indicatorSeparator {
    display: none;
}

.css-1fdsijx-ValueContainer {
    padding: 0;
}

.css-13cymwt-control,
.css-13cymwt-control:hover,
.css-13cymwt-control:focus,
.css-13cymwt-control:active {
    background-color: #8080801a !important;
    border: 0 !important;
}

.cvltr-namewidth {
    width: 145px;
}

.btn-blue,
.btn-blue:hover,
.btn-blue:active,
.btn-blue:focus {
    color: var(--secondary-cvltr-blue-color);
    font-size: 15px;
    font-weight: 500;
    background-color: var(--primary-cvltr-light-color);
    border-color: var(--primary-cvltr-light-color);
}

.cvltr-signaturebox {
    width: 365px;
    font-size: 1.6rem;
    color: #2e2e2e;
    padding: 0.8rem;
    font-weight: 400;
    border: 0.1rem solid var(--primary-cvltr-gray-shade3-color);
    background-color: var(--primary-cvltr-gray-shade2-color);
    min-height: 14rem;
    margin-bottom: 0.6rem;
    position: relative;
}

.cvltr-signaturebox button {
    position: absolute;
    right: 3px;
}

.cvltr-black-sign{
    color: var(--primary-cvltr-darkblack-color) !important;
}
.cvltr-blue-sign{
    color: var(--secondary-cvltr-darkblue-color) !important;
}

.cvltr-color {
    margin-top: 1rem;    
}

.btn-smtext {
    font-size: 20px;
    line-height: 28px;
}

.btn-mdtext {
    font-size: 25px;
    line-height: 28px;
}

.cvltr-select-width {     
    width: 72%;
    font-size: 30px;
 }

.cvltr-uploadimgbox {
    border-width: 0.1rem;
    border-radius: 0.2rem;
    border-color: #000;
    border-style: dashed;
    background-color: var(--primary-cvltr-gray-color);
    padding: 7rem 1rem 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cvltr-uploadimgbox h2 {
    color: var(--primary-cvltr-darkblack-color);
}

.cvltr-upldimg input[type="file"] {
    display: none;
}

.cvltr-upldimg .custom-file-upload {

    display: inline-block;

    cursor: pointer;
}

.cvltr-alignment svg.svg-inline--fa.fa-align-left {
    font-size: 18px;
    color: #cdcdcd;
}

.cvltr-alignment svg.svg-inline--fa.fa-align-left:hover {
    color: #2e2e2e;
}

.cvltr-alignment svg.svg-inline--fa.fa-align-right {
    font-size: 18px;
    color: #cdcdcd;
}

.cvltr-alignment svg.svg-inline--fa.fa-align-right:hover {
    color: #2e2e2e;
}

.cvltr-alignment svg.svg-inline--fa.fa-align-center {
    font-size: 18px;
    color: #cdcdcd;
}

.cvltr-alignment svg.svg-inline--fa.fa-align-center:hover {
    color: #2e2e2e;
}

.cvltr-color svg.svg-inline--fa.fa-circle {
    font-size: 18px;
    border: 1px solid transparent;
    border-radius: 50%;
    padding: 2px;
}

.cvltr-color svg.svg-inline--fa.fa-circle:hover {
    border: 1px solid black;
    border-radius: 50%;
    padding: 2px;
}

.cvltr-color-border-black svg.svg-inline--fa.fa-circle {
    border: 1px solid black;
    border-radius: 50%;
    padding: 2px;
}


.cvltr-bluecircle svg.svg-inline--fa.fa-circle {
    color: #2027aa;
}

.cvltr-size button:hover {
    outline: 2px dashed #0084d1 !important;
    outline-offset: 4px;
    border-radius: 0;
}

.cvltr-btn-blue:hover {
    border-color: var(--secondary-cvltr-blue-color);
    background-color: var(--primary-cvltr-white-color);
    color: var(--secondary-cvltr-blue-color);
}

@media (max-width: 767.98px) {
    .cvltr-signaturebox {
        width: 310px; }

   .cvltr-mx-0 { margin-left: 0; margin-right: 0; }

    .nav-item {
        margin-right: 5px;
    }

    .cvltr-select-width {
        width: 25%;
    }

    body {
        overflow-x: hidden;
    }

    .cvltr-namewidth {
        width: 73px;
    }

    .cvltr-me-res {
        margin-right: 15px !important;
    }

    .cvltr-w-50 {
        width: 50%;
    }

}