.thank-you {


    &--box:not(:last-child) {
        padding: 20px 0;

        h1 {
            margin-bottom: 2rem;
        }

        h3,
        h4 {
            margin: 0;
            font-weight: bold;
            line-height: 1.5;
        }

        p:last-child {
            margin: 0;
        }
    }

    .box-footer {
        display: flex;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        padding: 2.4rem 1.2rem;
        margin-left: -1.4rem;
        margin-right: -1.4rem;
        border-radius: 0 0 0 0;
    }

    .btn-green {
        font-size: 1.8rem;
        padding: 1.55rem 4.5rem;

        @media (max-width: 767px) {
            padding: 1.3rem 4.2rem;
            font-size: 1.5rem;
        }
    }
}