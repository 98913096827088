@import "./variables";

.form-wrap {
  padding: 0 15px;
  margin: 4.2rem auto;
  max-width: 544px;

  @media(max-width:991px) {
    padding: 0px;
    margin: 20px auto;
  }

  @media (max-width:767px) {
    padding: 0px;
  }

  .main-headline {
    margin-bottom: 8px;
  }

  .sub-headline {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .sub-para {
    font-size: 1.5rem;
    margin-bottom: 3rem;
  }
}

.form-container {
  max-width: 420px;
  margin: 0 auto;
  padding: 0 0.5rem;

  &--footer {
    padding: 1.25rem 0;

    @media (max-width:767px) {
      padding: 0;
    }

    p:first-child {
      margin-bottom: 1rem;
    }
  }

  // .small {
  //   font-size: 1.6rem;
  //   line-height: 25px;
  //   &.small-text {
  //     padding: 1.25rem 0;
  //   }
  //   .forgot-password {
  //     padding-top: 5px;
  //     margin-bottom: 0.75rem;
  //   }
  //   .create-account {
  //     // font-size: 10px;
  //   }
  // }
}

.or-container {
  font-family: sans-serif;
  margin: 20px auto;
  color: $color-black;
  text-align: center;
  font-size: 20px;
  max-width: 220px;
  position: relative;
}

.or-container:before {
  content: "";
  display: block;
  width: 85px;
  height: 1px;
  background: #aaa;
  left: 0;
  top: 50%;
  position: absolute;
}

.or-container:after {
  content: "";
  display: block;
  width: 85px;
  height: 1px;
  background: #aaa;
  right: 0;
  top: 50%;
  position: absolute;
}

// .or-container {
//   font-size: 20px;
//   position: relative;
//   padding: 0 0 10px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin: 0 auto;
//   max-width: 250px;
//   width: 100%;

//   span {
//     width: 149px;
//     height: 1px;
//     display: block;
//     background: #aaa;
//     margin: 0 15px;
//     padding: 0;
//   }
// }

.subtext-signup-button {
  text-align: center;
  margin: 10px;
}

.social-login {
  max-width: 250px;
  margin: 0 auto;

  button {
    width: 100%;
  }

  &--facebook {
    margin-bottom: 10px;
    line-height: 37px;
    position: relative;

    .metro {
      border-radius: 2px;
      font-size: 15px;
      font-weight: normal;
      background-color: #1877f2;
    }

    .kep-login-facebook {
      padding: 0;
      text-align: center;
      padding-left: 37px;
      text-transform: initial;
      font-family: 'Rubik', sans-serif;

      i {
        color: #4c69ba;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background-color: $color-white;
        border-radius: 2px;
      }
    }

    .facebook-login-button {
      height: 37px;
      background-color: $color-white;
      width: 38px;
      display: block;
      left: 2px;
      position: absolute;
      border-radius: 2px;

      @media (max-width:767px) {
        bottom: 1px;
        width: 38px;
        height: 39px;
        top: 1px;
      }

      @media (max-width:992px) {
        bottom: 1px;
        width: 36px;
        height: 36px;
        top: 1px;
      }
    }
  }

  &--google {
    margin-bottom: 10px;
    text-align: center !important;

    button {
      text-align: center;
      display: block !important;
      padding-left: 38px !important;

    }

    .google-login-button {
      background-color: #ea4335 !important;
      font-weight: normal !important;
      font-size: 16px !important;
      text-align: center;
      position: relative;
      display: block;
      line-height: 20px;
      position: relative;
      line-height: 37px;

      span {
        font-weight: 400 !important;
        font-family: 'Rubik', sans-serif !important;
      }

      div {
        position: absolute;
        left: 0;
        line-height: normal;
        padding: 9px 10px 8px !important;
      }

    }
  }

}