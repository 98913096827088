// LOGO
@import "/src/assets/scss/variables";

.header__logo {
    text-align: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
}

.language-select {
    /* margin-top: 100px; */
    top: 70px;
    left: 0px;
    border: #2ebe6e 1px solid;
    border-radius: 10px;
    position: absolute;
    z-index: 999999;
}

@media (max-width: 460px) {
    .header__logo {
        padding-top: 0.75rem
    }
}

@media (min-width: 992px) {
    .header__logo {
        flex: initial;
        padding-top: 0;
        padding-bottom: 0
    }
}

.header__logo>.logo {
    display: flex
}

.header__logo img,
.header__logo svg {
    height: 45px;
    max-width: 100%
}

@media (max-width: 768px) {

    .header__logo img,
    .header__logo svg {
        height: 35px
    }
}

// STEPS

.steps {
    list-style: none;
    margin: 0;
    font-size: 1.5rem;
    padding-left: 0;
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    -webkit-justify-content: space-between
}

.steps li {
    position: relative;
    margin-bottom: 0;
    display: flex;
    align-items: center
}

li.steps__item {
    display: none
}

li.steps__item:not(:last-child) {
    margin-right: 1rem;

    @media (max-width:767px) {
        margin-right: -1rem;
    }
}

li.steps__item.active {
    display: flex
}

li.steps__item.active .steps__label {
    color: #2ebe6e;
    font-weight: 700
}

li.steps__item:not(.steps__completed):not(.active) .steps__label {
    opacity: 0.5
}

@media (min-width: 992px) {
    li.steps__item {
        display: flex
    }
}

.steps__number {
    border-radius: 4px;
    border: 1px solid #c1c0c5;
    display: inline-flex;
    color: white;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 1;
    align-items: center;
    justify-content: center;
    font-size: 90%;
    margin-right: 1rem;

    @media (max-width:767px) {
        width: 1.7rem;
        height: 1.7rem;
        margin-right: 0.2rem;

    }
}

.steps__completed .steps__number {
    border: 1px solid #c1c0c5;
    color: #2ebe6e;
    background: transparent
}

.active .steps__number {
    background: #2ebe6e;
    color: white;
    border: 0
}

.steps__label {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.8em;

    @media(min-width: 992px) {
        font-size: 0.6em;
    }

    @media(min-width: 1240px) {
        font-size: 0.8em;
    }
}

// @media (min-width: 992px) {
//     .steps__label {
//         font-size: .6em
//     }
// }

// @media (min-width: 1240px) {
//     .steps__label {
//         font-size: .8em
//     }
// }


.header .navsteps {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid rgba(193, 192, 197, 0.5);
}