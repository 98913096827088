.added-item {
    border: 1px solid #c1c0c5;
    padding: 1rem 1.75rem;
    margin-bottom: 1rem;
    border-radius: 3px;
    box-shadow: 0 0 5px rgb(0 0 0 / 10%);
    display: flex;
    // width: 99%;
    // margin-left: 5px;
    justify-content: space-between;
    align-items: center;
    background: white;
    // @media (max-width: 767px) {
    //     width: 94.5%;
    //     margin-left: 10px !important;
    // }
    .added_item__details {
        .added-item__title {
            display: block;
            font-size: 1.4rem;
            span {
                b {
                    font-weight: bold;
                }
            }
        }
        .added-item__date {
            font-size: 1.4rem;
            font-family: "Open Sans TTF", sans-serif;
        }
        small.added-item__content {
            font-size: 1.4rem;
        }
    }
    .added-item__actions {
        .added-item__action {
            color: #d2d2d2;
            padding-left: 1rem;
            font-size: 2rem;
            &:hover {
                color: #2ebe6e;
                text-decoration: none;
            }
        }
    }
}