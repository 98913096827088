/*  */
.cvltr-red-color {
  color: #ff6600;
}

.cvltr-body-spacing {
  padding: 80px 20px 30px 70px;
}

/* .cvltr-wrapper-activebox {
  border: 0.3rem solid #a2d7ce;
  background-color: #f9fafb;
  padding-bottom: 10px;
  margin-bottom: 2rem;
} */

/* .cvltr-wrapper-activeheading {
  background-color: #92d9ce;
  color: #070707;
  padding: 0 1.7rem;
  height: 2.8rem;
} */

/* .cvltr-wrapper-img {
  margin-top: -1px;
}

.cvltr-wrapper-activecontent {
  padding: 0 3rem;
}

.cvltr-wrapper-content p {
  font-size: 15px;
}

.cvltr-wrapper-editimg {
  margin-right: 15px;
  margin-top: -15px;
}

.cvltr-wrapper-editimg img {
  width: 30px;
  height: 30px;
} */

/* .cvltr-wrapper-box {
  border: 0.1rem solid #cdcdcd;
  background-color: #fff;
  padding-bottom: 10px;
  margin-bottom: 2rem;
  cursor: pointer;
} */

/* .cvltr-wrapper-heading {
  background-color: #2e2e2e;
  color: #fff;
  padding: 0 1.7rem;
  display: inline-block;
  height: 2.8rem;
  margin-bottom: 12px;
} */

/* .cvltr-wrapper-content {
  padding: 0 3rem 11px 3rem;
}

.cvltr-list-wrapper {
  padding-right: 20px;
  padding-bottom: 40px;
} */

/* .cvltr-modal .modal-header {
  border-bottom: 0;
  padding: 3rem 4.5rem 0 4.5rem;
}

.cvltr-modal .modal-title {
  margin: 0 !important;
  font-size: 2.4rem;
  letter-spacing: -0.047rem;
} */

/* .cvltr-modal .modal-body {
  padding: 1.5rem 4.5rem 1.5rem;
} */

/* .cvltr-modalsection {
  border: 0.1rem solid #cdcdcd;
  border-radius: 0;
  padding: 0;
  background-color: #f9fafb;
}

.cvltr-sectionheader {
  padding: 1.5rem 2rem;
  width: 100%;
  height: 4rem;
}

.cvltr-sectionbody {
  padding: 1rem 2.5rem 2.2rem;
  margin-top: 2rem;
  height: 20rem;
}

.cvltr-modal .modal-footer {
  border-top: 0;
  padding: 1rem 4.5rem 3.5rem;
}

.cvltr-modal .btn-secondary {
  background-color: #ffffff;
  border-color: #000;
  color: #2e2e2e;
  font-weight: 500;
  font-size: 1.8rem;
  padding: 1.55rem 4.5rem;
  font-family: "Rubik", sans-serif;
  text-transform: uppercase;
} */

/* .cvltr-chip {
  height: 41px;
  padding: 0.35rem 2.3rem 1rem 1.3rem;
  height: 32px;
} */

/* .cvltr-chip a {
  color: #2e2e2e;
  font-size: 2rem;
} */

/* .cvltr-chip img {
  width: 16px;
}

.cvltr-activechip {
  border: 0.1rem solid #3c78d3 !important;
  background-color: #3c78d3 !important;
}

.cvltr-activechip a {
  color: #fff;
} */

/* .cvltr-steps {
  margin-top: 15.5rem;
}

.cvltr-steps {
  position: relative;
  margin-left: 60px;
} */

/* .range-slider__wrap .range-slider__tooltip .range-slider__tooltip__label {
  background-color: #f9fafb;
  width: 16rem;
  height: 11.4rem;
  border: 0.1rem solid #cdcdcd;
  color: #2e2e2e;
  font-size: 5.8rem;
  text-align: center;
  padding: 2rem 0 1rem 0;
  margin-bottom: 3.5rem;
  top: -83px;
  user-select: none;
  cursor: pointer;
  position: absolute;
}

.range-slider__wrap
  .range-slider__tooltip.range-slider__tooltip--top
  .range-slider__tooltip__caret::before {
  top: -20px;
  border-width: 2.4rem 3.4rem 0px;
  border-top-color: #f9fafb;
}

.range-slider__wrap
  .range-slider__tooltip
  .range-slider__tooltip__caret::before {
  left: -24px;
}

.cvltr-year-text {
  font-size: 2rem;
  margin-top: 0.9rem;
  text-transform: uppercase;
  display: block;
}

.cvltr-step {
  font-size: 2rem;
  cursor: pointer;
  color: silver;
  margin-right: -2%;
}

.cvltr-activestep {
  color: #000;
}

.range-slider__wrap {
  height: calc(1.5em + -0.15rem + 2px);
} */

/* .cvltr-year-text::after {
  content: "";
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAAAXCAIAAAAnV54yAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHeSURBVFhH7dfZcoJAEEBR/v/nFARFiEQJ+yaLyKaSNj2KoWIUhaBWbs2TyzBHqlqlsrx4yUUt/aD20AusMIoojuNYlrNtp/bcky7XW06nU4ZhqLIsfd/neZ5lWcu2a697omXZDtwkyHGc3W63h2EVz3omXprlumGMRiM4PBAIpiwrGBYEAfLMh+claaqoKk3ToihGUUQAh+ow7IRn1bZ7hBWvk4UsD4dDSZKSJCGH/t7PMAx5cJdN04I7Xtu9lxWtYkl6B5Isy1mWkYP+1G8w7MgzTLNHXhCEovgGJFVVi6IghzvfZRjWI891PZ7fT3DDMLbbLTnQpa6FYRXP6JwH+8MA+/qaZW3bhglODnFdzWAY8uAjhDnbBQ/21PX9BJ9MJp7nkas27BYYVvH01njrJP1QFJjggiCEYUiudFO3wzC4PP6E0e7jxfF6vlgMBoPZbBbHMdn9ju6FYcij9zy9KS+MVjNJAtJ8Pk/TlOx4d+3AMMKjGU27iucHoSCKMMEVRcnznOzSUm3CsAOPVjXtHM9x3QnPw2t0Xd9sNuSdrdY+DDvlJWmGHnDCjxiW42DiWZbVdII3qisYduTBrFNUDWbMeDx2XbdTEtYtDEMedPq3ouv+AtZL/7Dnqiw/AbXD+ht1BjznAAAAAElFTkSuQmCC");
  content: "";
  display: block;
  width: 9.2rem;
  height: 2.5rem;
  position: absolute;
  left: 3.5rem;
  bottom: -2.29rem;
} */

.range-slider__wrap .range-slider__tooltip .range-slider__tooltip__caret {
  display: none;
}

.cvltr-chipadd .form-control {
  width: 150px;
  background: #fff;
  border-radius: 2.1rem;
  /* height: 5rem; */
  font-size: 1.5rem;
  border: 1px solid #cdcdcd;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS43NSA0QzEyLjE2NDIgNCAxMi41IDQuMzM1NzkgMTIuNSA0Ljc1VjE4Ljc1QzEyLjUgMTkuMTY0MiAxMi4xNjQyIDE5LjUgMTEuNzUgMTkuNUMxMS4zMzU4IDE5LjUgMTEgMTkuMTY0MiAxMSAxOC43NVY0Ljc1QzExIDQuMzM1NzkgMTEuMzM1OCA0IDExLjc1IDRaIiBmaWxsPSIjMDA1ZmIwIi8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNCAxMS43NUM0IDExLjMzNTggNC4zMzU3OSAxMSA0Ljc1IDExSDE4Ljc1QzE5LjE2NDIgMTEgMTkuNSAxMS4zMzU4IDE5LjUgMTEuNzVDMTkuNSAxMi4xNjQyIDE5LjE2NDIgMTIuNSAxOC43NSAxMi41SDQuNzVDNC4zMzU3OSAxMi41IDQgMTIuMTY0MiA0IDExLjc1WiIgZmlsbD0iIzAwNWZiMCIvPgo8L3N2Zz4K);
  background-repeat: no-repeat;
  background-position: right 7px center;
}

.cvltr-chipadd .form-control:hover,
.cvltr-chipadd .form-control:focus,
.cvltr-chipadd .form-control:active {
  border: 1px solid #005fb0;
}

.cvltr-delete {
  width: 16px;
}

.cvltr-edit {
  width: 20px;
}

.cvltr-skilladd {
  background-color: #fff;
  border: 0.1rem solid #cdcdcd;
  border-radius: 50%;
  /* height: 33px;
  width: 33px; */
  padding: 5px 8px;
  cursor: pointer;
  display: inline-block;
}

.cvltr-addimg {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS43NSA0QzEyLjE2NDIgNCAxMi41IDQuMzM1NzkgMTIuNSA0Ljc1VjE4Ljc1QzEyLjUgMTkuMTY0MiAxMi4xNjQyIDE5LjUgMTEuNzUgMTkuNUMxMS4zMzU4IDE5LjUgMTEgMTkuMTY0MiAxMSAxOC43NVY0Ljc1QzExIDQuMzM1NzkgMTEuMzM1OCA0IDExLjc1IDRaIiBmaWxsPSIjMTExMTExIi8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNCAxMS43NUM0IDExLjMzNTggNC4zMzU3OSAxMSA0Ljc1IDExSDE4Ljc1QzE5LjE2NDIgMTEgMTkuNSAxMS4zMzU4IDE5LjUgMTEuNzVDMTkuNSAxMi4xNjQyIDE5LjE2NDIgMTIuNSAxOC43NSAxMi41SDQuNzVDNC4zMzU3OSAxMi41IDQgMTIuMTY0MiA0IDExLjc1WiIgZmlsbD0iIzExMTExMSIvPgo8L3N2Zz4K);
  display: inline-block;
  width: 17px;
  height: 16px;
  margin-top: 4px;
  background-size: 18px;
}

.cvltr-addimg::before {
  content: "";
}

.cvltr-add-skill-input span {
  margin-left: -40px;
  height: 30px;
  margin-top: 1px;
  border-bottom-right-radius: 2.1rem;
  border-top-right-radius: 2.1rem;
  cursor: pointer;
}

.cvltr-add-skill-input .form-control {
  background-image: none !important;
  padding-right: 33px;
  padding-top: 5px;
}

.cvltr-skilladd:hover {
  background-color: #3c78d3;
}

.cvltr-skilladd:hover .cvltr-addimg {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS43NSA0QzEyLjE2NDIgNCAxMi41IDQuMzM1NzkgMTIuNSA0Ljc1VjE4Ljc1QzEyLjUgMTkuMTY0MiAxMi4xNjQyIDE5LjUgMTEuNzUgMTkuNUMxMS4zMzU4IDE5LjUgMTEgMTkuMTY0MiAxMSAxOC43NVY0Ljc1QzExIDQuMzM1NzkgMTEuMzM1OCA0IDExLjc1IDRaIiBmaWxsPSIjZmZmZmZmIi8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNCAxMS43NUM0IDExLjMzNTggNC4zMzU3OSAxMSA0Ljc1IDExSDE4Ljc1QzE5LjE2NDIgMTEgMTkuNSAxMS4zMzU4IDE5LjUgMTEuNzVDMTkuNSAxMi4xNjQyIDE5LjE2NDIgMTIuNSAxOC43NSAxMi41SDQuNzVDNC4zMzU3OSAxMi41IDQgMTIuMTY0MiA0IDExLjc1WiIgZmlsbD0iI2ZmZmZmZiIvPgo8L3N2Zz4K);
  background-repeat: no-repeat;
}

.cvltr-preview-spacing {
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 0 7px 0 rgb(66 74 91 / 40%);
  /* min-height: 60rem; */
  /* min-height: 40rem; */
  width: 200px;
  height: 280px;
  overflow: auto;
  margin-bottom: 15px;
  font-size: 0.6rem;
}

.cvltr-preview-spacing p {
  margin-top: 0;
}

.cvltr-preview-modal-spacing p {
  margin-top: 0;
}

.cvltr-preview-modal-header {
  border-bottom: 0;
  padding: 2rem 2rem;
}

@media print {
  .cvltr-preview-spacing {
    font-size: 19.2pt !important;
  }
  h1 {
    font-size: 31.2pt !important;
  }
}

@media only screen and (min-device-width: 1400px)  { 
  .cvltr-chip { height: auto;}
  /* .cvltr-skilladd {     padding: 6px 4px 4px 9px; } */
}

@media only screen and (min-device-width: 1000px) and (max-device-width: 1100px) and (orientation:portrait) {
  .range-slider__wrap .range-slider__tooltip .range-slider__tooltip__label {     top: -99px; }
  .cvltr-year-text::after { width: 7.2rem;
    left: 4.5rem;}
    .cvltr-chip { height: auto;}
    .cvltr-skilladd {     padding: 4px 4px 4px 8px; }
    .cvltr-add-skill-input span {     margin-top: 5px; }
 }

@media (max-width: 991.98px) {
  .cvltr-chip { height: auto;}
  .cvltr-add-skill-input .form-control {     padding-top: 2px; }

 } 

@media only screen and (min-device-width: 768px) and (max-device-width: 992px) and (orientation: portrait) {
  .cvltr-add-skill-input .form-control {     padding-top: 2px; }
  .cvltr-add-skill-input span {     margin-top: 5px; }
  .cvltr-skilladd {      padding: 5px 4px 4px 7px; }
  .cvltr-steps {
    position: relative;
    margin-left: 47px;
    margin-right: 35px;
}

  .range-slider__wrap .range-slider__tooltip .range-slider__tooltip__label {     top: -99px; }

  .cvltr-year-text::after {
    width: 7.2rem;
    height: 2.5rem;
    position: absolute;
    left: 4.5rem;
    bottom: -2.29rem;
}

}

@media (max-width: 767.98px) {

  .cvltr-add-skill-input span {
    margin-left: -42px;
    margin-top: 6px;
  }

  /* .cvltr-skilladd {
    padding: 5px 8px;
  } */

  /* .cvltr-steps {
    margin-left: 0;
  }

  .cvltr-step {
    margin-right: -5%;
  }

  .cvltr-year-text {
    font-size: 0.8rem;
    line-height: 1.2rem;
    margin-top: 0;
  }

  .range-slider__wrap .range-slider__tooltip .range-slider__tooltip__label {
    padding: 0.1rem 0 0.1rem 0;
    width: 5.5rem;
    height: 5.4rem;
    font-size: 1.5rem;
    top: -19px;
  }

  .range-slider__wrap {
    height: calc(1.5em + -1.15rem + 2px);
  }

  .cvltr-year-text::after {
    background-image: none;
    width: 0;
    height: 0;
    border: 0.6rem solid #000;
    border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fbfbfb #fbfbfb;
    transform-origin: 0 0;
    transform: rotate(-46deg);
    box-shadow: -0.3rem 0.3rem 0.3rem rgb(0 0 0 / 20%);
    left: 1.8rem;
    bottom: -1rem;
  }

  .cvltr-body-spacing {
    padding: 20px;
  } */

  /* .cvltr-modal .modal-header {
    padding: 8px;
  }

  .cvltr-modal .modal-body {
    padding: 8px;
  }

  .cvltr-modal .modal-footer {
    padding: 8px;
  }

  .cvltr-modal .btn-secondary {
    padding: 1.3rem 4.2rem;
    font-size: 1.5rem;
  } */
}

@media screen and (min-width: 992px) {
  /* .scroll-sidebar {
    max-height: calc(calc(var(--vh, 1vh) * 100) - 60px - 60px - 90px);
    min-height: calc(calc(var(--vh, 1vh) * 100) - 60px - 60px - 90px);
    margin-bottom: 0px !important;
  } */

  .modal-lg {
    --bs-modal-width: 700px;
  }
}
