.cvltr-layout.cvltr-3 h1 {
  margin-top: 0;
  text-transform: capitalize;
  font-weight: 900;
  font-family: "Roboto TTF",sans-serif;
}
.cvltr-layout.cvltr-3 {
  font-family: "Roboto TTF",sans-serif;
}
.cvltr-layout.cvltr-3 .cvltr-headingdetails {
  margin-bottom: 30px;
}
.cvltr-layout.cvltr-3 .cvltr-headingdetails p {
  margin-top: 0;
  margin-bottom: 0;
}
.cvltr-layout.cvltr-3 .cvltr-headingdetails svg {
  /* margin-top: 8px; */
  margin-right: 7px;
}
.cvltr-layout.cvltr-3 .cvltr-personalinfo svg {
  /* margin-top: 8px; */
  margin-right: 7px;
}
.cvltr-layout.cvltr-3 .cvltr-d-flex {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}
.cvltr-layout.cvltr-3 .cvltr-d-flex p { margin-bottom: 0; }

.cvltr-layout.cvltr-3 .cvltr-headinginfo p {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 2px;
}
.cvltr-layout.cvltr-3 .cvltr-subheading h2 {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 10px;
}
.cvltr-layout.cvltr-3 .cvltr-companyinfo p {
  margin-bottom: 5px;
  margin-top: 0;
}
.cvltr-layout.cvltr-3 .cvltr-mt30 {
  margin-top: 30px;
}
.cvltr-layout.cvltr-3 .cvltr-mb30 {
  margin-bottom: 30px;
}
.cvltr-layout.cvltr-3 .cvltr-lable {
  display: none;
}
.cvltr-layout.cvltr-3 .cvltr-subheading {
  display: none;
}
.cvltr-layout.cvltr-3 .cvltr-emailnone {
  display: none;
}
.cvltr-layout.cvltr-3 .cvltr-information {
  display: none;
}
.cvltr-layout.cvltr-3 .cvltr-dother-none {
  display: none;
}
.cvltr-layout.cvltr-3 .show-comma {
  display: none;
}
.cvltr-layout.cvltr-3 .cvltrflexwrap { flex-wrap: wrap !important; }