.cvltr-layout.cvltr-8 h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 900;
  font-family: "Open Sans TTF",sans-serif;
}
.cvltr-layout.cvltr-8 {
  font-family: "Open Sans TTF",sans-serif;
}
.cvltr-layout.cvltr-8 .cvltr-graybackground {
  background-color: #c2c2c2;
}
.cvltr-layout.cvltr-8 .cvltr-graybackground h2 {
  padding: 5px;
}
.cvltr-layout.cvltr-8 .cvltr-graybackground p {
  padding: 10px;
}
.cvltr-layout.cvltr-8 .cvltr-d-flex {
  display: flex;
  /* align-items: center; */
}
.cvltr-layout.cvltr-8 .cvltr-rightspacing {
  min-width: 65px;
}
.cvltr-layout.cvltr-8 .cvltr-companyinfo p {
  margin-bottom: 5px;
  margin-top: 0;
}
.cvltr-layout.cvltr-8 .cvltr-addressinfo p {
  margin-top: 0;
  margin-bottom: 0;
}
.cvltr-layout.cvltr-8 .cvltr-addressinfo {
  border-bottom: 2px solid #dddddd;
}
.cvltr-layout.cvltr-8 .cvltr-mt30 {
  margin-top: 30px;
}
.cvltr-layout.cvltr-8 .cvltr-mb30 {
  margin-bottom: 30px;
}
/* .cvltr-layout.cvltr-8 .cvltr-mb0 {
  margin-bottom: 0px;
} */
.cvltr-layout.cvltr-8 .cvltr-mb10 {
  margin-bottom: 10px;
}
.cvltr-layout.cvltr-8 .cvltr-img {
  display: none;
}
.cvltr-layout.cvltr-8 .cvltr-emaildisplay {
  display: none;
}
.cvltr-layout.cvltr-8 .cvltr-lable b {
  min-width: 75px;
  display: inline-block;
}
.cvltr-layout.cvltr-8 .cvltr-dother-none {
  display: none;
}
.cvltr-layout.cvltr-8 .show-comma {
  display: none;
}
.cvltr-layout.cvltr-8 .cvltr-information { margin-top: 10px;}

/* .cvltr-layout.cvltr-8 .cvltrflexwrap { flex-wrap: wrap !important; } */

