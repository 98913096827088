@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&family=Rubik:wght@300;400;600&display=swap");
@import "~bootstrap/scss/bootstrap";
@import "./assets/scss/variables";
@import "./assets/scss/fa";
@import "./assets/scss/fonts";
@import "./assets/scss/reset";
// font-family: 'Open Sans', sans-serif;
// font-family: 'Rubik', sans-serif;
// html {
//   position: relative;
//   min-height: 100%;
//   scroll-behavior: smooth;
//   -webkit-box-sizing: border-box;
//   box-sizing: border-box;
//   -moz-text-size-adjust: 100%;
//   -ms-text-size-adjust: 100%;
//   -o-text-size-adjust: 100%;
//   -webkit-text-size-adjust: 100%;
//   text-size-adjust: 100%;
// }

// html,
// body {
//   height: 100%;
//   font-size: 100%;
//   color: $base-color;
//   font-family: $font-stack-body;
//   font-weight: $font-weight-light;
//   line-height: $line-height-base;
// }
// a {
//   color: var(--bs-blue);
//   &:hover {
//     color: var(--bs-blue);
//   }
// }

// h1,
// .h1,
// h2,
// .h2,
// h3,
// .h3,
// h4,
// .h4,
// h5,
// .h5,
// h6,
// .h6 {
//   margin: 0 0 1.5rem;
//   padding: 0;
//   font-family: $font-stack-heading;
//   font-weight: $font-weight-heading;
//   font-weight: 400;
// }

// h1,
// .h1 {
//   font-size: 1.3rem;
//   margin-bottom: 10px;
//   text-transform: uppercase;
//   font-weight: 600;
//   letter-spacing: 0.03em;

//   @media (max-width: 992px) {
//     font-size: 1.6rem;
//   }
//   @media (max-width: 767px) {
//     font-size: 1.8rem;
//   }
// }

// h2,
// .h2 {
//   font-size: 1.25rem;
//   margin-bottom: 10px;
//   font-weight: 600;
// }

// h3,
// .h3 {
//   font-size: 1.125rem;
//   margin-bottom: 10px;
//   font-weight: 600;
// }

// h4,
// .h4 {
//   font-size: 1rem;
//   margin-bottom: 10px;
//   font-weight: 600;
// }

// h5,
// .h5 {
//   font-size: 0.875rem;
//   margin-bottom: 10px;
//   font-weight: 600;
// }

// p {
//   margin: 0 0 1rem;
//   padding: 0;
// }
:root {
  --bs-success-rgb: 46, 190, 110;
}

* {
  word-break: break-word;
  box-sizing: border-box;
}

b,
strong {
  font-weight: bold;
}

a,
.btn {
  @include transition(all 0.4s ease);
}

a {
  color: $secondary-color;
  text-decoration: none;

  &:hover {
    color: $secondary-color;
  }
}

button {
  color: inherit;
  border: 0;
  background-color: transparent;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

img {
  max-width: 100%;
  height: auto;
  border: 0;
}

// .navbar > .container,
// .container {
//   max-width: 100%;
//   width: 100%;
// }
// @media (min-width: 1240px) {
//   .navbar > .container,
//   .container {
//     max-width: 1240px;
//   }
// }
// @media (min-width: 1400px) {
//   .navbar > .container,
//   .container {
//     max-width: 1240px;
//   }
// }
* {
  scrollbar-color: #dce0e7 #f8fafc;
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
  width: 9px;
  height: 0px;
  -moz-appearance: none !important;
}

*::-webkit-scrollbar-track,
*::-webkit-scrollbar-track-piece {
  background-color: #f8fafc;
  -moz-appearance: none !important;
  border: none !important;
  box-shadow: none;
}

*::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb:vertical,
*::-webkit-scrollbar-thumb:horizontal {
  background-color: #dce0e7;
  outline: none;
  border-radius: 4px;
  -moz-appearance: none !important;
}

.header {
  .rb-logo {
    height: 45px;
    width: 150px;

    @media screen and (min-width: 1200px) and (max-width: 1599px) {
      height: 35px;
      width: 117px;
    }

    @media (max-width: 768px) {
      height: 35px;
      width: 100px;
    }

    @media (max-width: 991px) {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  .navbar-expand-lg {
    // padding: 2rem 0;
    padding: 1.5rem 0;

    @media (max-width: 778px) {
      padding: 0;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    }

    @media (max-width: 767px) {
      padding-top: 0.75rem;
      padding-bottom: 1.25rem;
    }

    @media (max-width: 760px) {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    }

    // @media screen and (min-width: 768px) and (max-width: 991px) {
    //   padding: 1.5rem 0 1.25rem;
    // }
    .container {
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 10px;
      border-bottom: 1px solid #ebebeb;

      @media screen and (min-width: 1200px) and (max-width: 1599px) {
        padding-left: 12px;
        padding-right: 12px;
      }

      &.borderBottomRmv {
        border: none !important;
      }
    }
  }
}

.footer {
  .container {
    @media (max-width: 767px) {
      padding-bottom: 65px;
    }
  }
}

label {
  display: block;
  max-width: 100%;
  line-height: 1.5em;
  margin-bottom: 0.7rem;
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0.05rem;

  &.form-label {
    margin-bottom: 0.7rem;
  }
}

.form-group {
  margin-bottom: 1.7rem;

  .form-text {
    font-size: 1.1rem;
  }
}

.form-control {
  padding: 0.6em 1em;
  color: #222;
  outline: none;
  font-size: inherit;
  line-height: 1.5;
  border: 1px solid #c1c0c5;

  &:focus {
    color: #222;
    background-color: $color-white;
    border-color: #2196f3;
    outline: 0;
    box-shadow: none;
  }

  &.success {
    border-color: $secondary-color;
  }
}

.user-settings .form-control {
  padding: 0.6em 3em;
}

.checkout .checkoutpage-user-logo .form-control {
  padding: 0.6em 2.5em;
}

select,
textarea {
  width: 100%;
  max-width: 100%;
  display: block;
  color: #222;
  border: 1px solid #c1c0c5;
  border-radius: 4px;
  font-size: 14px;
  padding: 0.75em 1em;
  height: 4.3rem;
  transition: background 0.2s ease-out, border 0.2s ease-out;
  background-image: none;
  background-color: white !important;
  margin-bottom: 0.2em;

  @media screen and (min-width: 1200px) and (max-width: 1599px) {
    padding: 0.4em 1em;
  }

  &:focus {
    color: #222;
    background-color: $color-white;
    border-color: #2196f3;
    outline: 0;
    box-shadow: none;
  }

  &.success {
    border-color: $secondary-color;
  }
}

.fa,
input[type="checkbox"] + label::before,
.select2-selection__arrow b,
.user-info__logout {
  text-decoration: none;
  display: inline-block;
}

.fa,
input[type="checkbox"] + label::before,
.select2-selection__arrow b,
.user-info__logout {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}

.btn .fa {
  padding-right: 5px;
  margin-top: 2px;
}

.btn {
  font-family: "Rubik", sans-serif;

  &:focus {
    box-shadow: none;
  }
}

.template-file__image .btn-primary {
  padding: 1.55rem 1.5rem;
}

.template-file__image .btn-primary .template-view {
  padding: 0px !important;
}

.cvltr-button-background {
  background-color: var(--secondary-cvltr-orange-color) !important;
}

.btn-primary {
  color: $color-white;
  background-color: $primary-color;
  border-color: $primary-color;
  font-weight: $font-weight-medium;
  font-size: 1.8rem;
  padding: 1.55rem 4.5rem;
  font-family: "Rubik", sans-serif;
  text-transform: uppercase;

  @media (max-width: 767px) {
    padding: 1.3rem 3.5rem;
    font-size: 1.5rem;
  }

  &:focus {
    outline: none;
    box-shadow: none;
    background-color: $primary-color;
    border-color: $primary-color;
  }

  &:hover {
    background-color: $primary-color-dark;
    border-color: $primary-color-dark;
  }

  &--heavy {
    padding: 1.5rem;
    font-size: 2.6rem;
    font-family: $font-stack-heavy;
    font-weight: $font-weight-medium;
  }

  &.intro-btn {
    min-width: 30rem;
    padding: 1.5rem;
    font-size: 2.6rem;
  }

  &.checkout-btn {
    padding: 10px 5px;
    background: #2ebe6e;
    border-color: #2ebe6e;
    width: 100%;
    margin-top: 10px;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    font-family: "Rubik", sans-serif;
    letter-spacing: 0.01em;

    @media (max-width: 767px) {
      background: #2ebe6e !important;
      border-color: #e1e8e4 !important;
    }

    @media (max-width: 991px) {
      width: 101%;
    }
  }

  &.add-new-section-btn {
    font-size: 90%;
    font-family: "Rubik", sans-serif;
    letter-spacing: 0.01em;
    padding: 0.5em 1em;
  }
}

.btn-secondary {
  color: $color-white;
  background-color: $secondary-color;
  border-color: $secondary-color;
  font-weight: $font-weight-medium;
  font-size: 1.75rem;
  padding: 1.25rem 4rem;
  font-family: "Rubik", sans-serif;
  text-transform: uppercase;

  @media (max-width: 767px) {
    padding: 1.3rem 4.2rem;
    font-size: 1.5rem;
  }

  &:focus {
    outline: none;
    box-shadow: none;
    background-color: $secondary-color-dark;
    border-color: $secondary-color-dark;
  }

  &:hover {
    background-color: $secondary-color-dark;
    border-color: $secondary-color-dark;
  }
}

.btn-outline-primary {
  color: $primary-color;
  border-color: $primary-color;

  &:hover {
    color: $color-white;
    background-color: $primary-color-dark;
    border-color: $primary-color-dark;
  }
}

.cvltr-btn-blue:hover {
  border-color: var(--secondary-cvltr-blue-color) !important;
  background-color: var(--primary-cvltr-white-color) !important;
  color: var(--secondary-cvltr-blue-color) !important;
}

.btn-outline-secondary {
  background-color: #f9f9f9;
  color: $color-black;
  font-size: 1.8rem;
  padding: 1.55rem 4.5rem;
  border: 1px #c1c0c5 solid;
  text-transform: uppercase;

  @media (max-width: 767px) {
    padding: 1.3rem 3.25rem;
    font-size: 1.5rem;
  }

  &:hover {
    border-color: $secondary-color;
    background-color: $color-white;
    color: $secondary-color;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.btn-primary-light--large {
  color: $secondary-color;
  border-color: $secondary-color;
  font-size: 1.8rem;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  padding: 1.55rem 4.5rem;

  &:hover {
    color: $primary-color-dark;
    border-color: $primary-color-dark;
  }
}

.btn-link {
  font-size: inherit;
  text-decoration: none;
  color: $secondary-color;
}

.reset-btn {
  text-transform: uppercase;
  font-size: 1.5rem;
  padding: 0.75rem 2.5rem;
  margin-bottom: 1rem;
}

.btn-container {
  align-items: center;
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  // margin-right: 30px;
  -webkit-align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;

  @media (max-width: 460px) {
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
  }

  @media (max-width: 992px) {
    // margin-right: -36px;
    margin-left: 5px;
  }
}

.main-content {
  min-height: 100%;
  position: relative;
  padding-top: 20px;
  min-height: calc(100vh - 25.8rem);

  @media (max-width: 991px) {
    min-height: calc(100vh - 231px);
  }

  hgroup {
    h1 {
      span {
        font-weight: normal;
      }
    }
  }
}

.box__content {
  padding: 2.4rem 1.2rem 0;
  margin-left: -2.4rem;
  margin-right: -2.4rem;
  margin-bottom: 2.4rem;
  position: relative;
}

@media (min-width: 992px) {
  .box__content {
    padding: 2.4rem 2.4rem 0;
  }
}

@media (min-width: 768px) {
  .box__footer--steps {
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
  }
}

.box__footer--steps.is-center {
  justify-content: center;
  -webkit-justify-content: center;
}

@media (max-width: 460px) {
  .box__footer--steps.is-center a.btn-primary {
    width: 100%;
  }
}

// Thumbs Preview SVG
.builder-info__image {
  display: none;
  margin-top: 20px;
  padding-top: 6px;

  @media (min-width: 992px) {
    display: flex;
    display: -webkit-flex;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
  }

  @media (min-width: 992px) {
    display: flex;
    display: -webkit-flex;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
  }
}

.resume-navigator__frame {
  stroke: #ebebeb;
  stroke-width: 2px;
  fill: white;
}

.resume-navigator__section {
  fill: rgba(0, 0, 0, 0.05);
}

.resume-navigator__label {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  fill: rgba(46, 190, 110, 0.8);
  display: none;
}

.resume-navigator--xp .resume-navigator__label--xp,
.resume-navigator--info .resume-navigator__label--info,
.resume-navigator--education .resume-navigator__label--education,
.resume-navigator--skills .resume-navigator__label--skills,
.resume-navigator--summary .resume-navigator__label--summary,
.resume-navigator--other .resume-navigator__label--other {
  display: block;
}

.resume-navigator--info .resume-navigator__section--info,
.resume-navigator--xp .resume-navigator__section--xp,
.resume-navigator--info .resume-navigator__section--info,
.resume-navigator--education .resume-navigator__section--education,
.resume-navigator--skills .resume-navigator__section--skills,
.resume-navigator--summary .resume-navigator__section--summary,
.resume-navigator--other .resume-navigator__section--other {
  fill: transparent;
  stroke: rgba(46, 190, 110, 0.5);
  stroke-dasharray: 5px;
}

.resume-navigator__corner {
  fill: #ebebeb;
  stroke: #ebebeb;
  stroke-width: 1px;
}

.btn-green {
  color: white;
  background-color: $secondary-color;
  border-color: $secondary-color;
  font-size: 1.8rem;
  font-weight: $font-weight-bold;
}

.btn-green:hover,
.btn-green:focus,
.btn-green:active {
  color: $secondary-color;
  background-color: white;
  border-color: $secondary-color;
  outline: 0;
  text-decoration: none;
}

.btn-primary-light {
  color: $secondary-color;
  background-color: transparent;
  border-color: $secondary-color;
}

.btn-primary-light:hover,
.btn-primary-light:focus,
.btn-primary-light:active {
  color: $primary-color;
  background-color: white;
  border-color: $primary-color;
  outline: 0;
  text-decoration: none;
}

.btn-muted {
  color: #222;
  background-color: #f9f9f9;
  border-color: #c1c0c5;
}

.btn-muted:hover,
.btn-muted:focus,
.btn-muted:active {
  color: $secondary-color;
  background-color: transparent;
  border-color: $secondary-color;
  outline: 0;
  text-decoration: none;
}

button i {
  margin-right: 5px;
}

.alert.add {
  background: $color-white;
  border: 1px dashed $secondary-color;
  color: $secondary-color;
  padding: 0.275em 1em;
  border-radius: 0;
  margin-top: 4rem;
}

.alert.edit {
  background: $color-white;
  border: 1px dashed $primary-color;
  color: $primary-color;
  padding: 0.275em 1em;
  border-radius: 0;
  margin-top: 4rem;
}

.alert.error {
  border: 1px solid $primary-color;
  background-color: rgba(254, 140, 38, 0.04);
  color: $primary-color;
  padding: 0.275em 1em;
  border-radius: 0;
  margin-top: 4rem;

  &.small {
    @media (max-width: 991px) {
      width: 100%;
      margin-left: 0;
    }
  }
}

.heading-extra {
  font-weight: normal;
}

.tooltip-wrapper {
  position: relative;
}

.tooltip {
  position: absolute;
  top: 1rem;
  right: 0.9rem;
  opacity: 1;
  z-index: 100;
}

.tooltip-trigger:hover,
.tooltip-trigger:focus {
  color: #2196f3;
}

.tooltip__content {
  opacity: 0;
  pointer-events: none;
  z-index: 1;
  transition: opacity 0.3s;
  position: absolute;
  background: #2196f3;
  color: white;
  border-radius: 2px;
  padding: 0.5rem 1rem;
  top: 110%;
  top: calc(100% + 12px);
  right: -10px;
  width: 250px;
  font-size: 1.2rem;
}

.tooltip__content:before {
  content: "";
  display: block;
  position: absolute;
  top: -16px;
  right: 13px;
  border: 6px solid transparent;
  border-bottom: 10px solid #2196f3;
}

@media (min-width: 992px) {
  .tooltip__content {
    right: calc(-132px + 1.2rem);
  }

  .tooltip__content:before {
    right: auto;
    left: calc(50% + 3px);
    margin-left: -6px;
  }
}

.tooltip-trigger:hover + .tooltip__content,
.tooltip-trigger:focus + .tooltip__content {
  opacity: 1;
}

.tooltip-trigger {
  font-size: 2.4rem;
  margin-left: 1.2rem;
  color: #ebebeb;
  transition: 0.3s;
}

select[disabled] {
  opacity: 0.5;
}

.form-text {
  font-size: 1.6rem;
}

.warning {
  .tooltip-wrapper {
    input:not([type="submit"]) {
      -webkit-animation: escale10 0.5s ease-out;
      animation: escale10 0.5s ease-out;
      border: 1px solid #f44336;
    }

    select {
      -webkit-animation: escale10 0.5s ease-out;
      animation: escale10 0.5s ease-out;
      border: 1px solid #f44336;
    }
  }
}

.btn-primary:disabled,
.btn-primary.disabled {
  background-color: $primary-color;
  border-color: $primary-color;
}

// button css

.btn-primary:disabled,
.btn-primary.disabled {
  background-color: $primary-color;
  border-color: $primary-color;
}

.rdw-list-wrapper {
  flex-grow: 1;
}

.text-editor-container .rdw-link-modal {
  width: 275px;
  height: 240px;
  left: -91px;
  background: #f2f2f2;

  @media (max-width: 767px) {
    top: 70px;
  }
}

.rdw-inline-wrapper.block-type .rdw-option-wrapper {
  text-indent: -9999px;
}

.rdw-inline-wrapper.block-type .rdw-option-wrapper:nth-child(1) {
  background: url(data:image/svg+xml,%3Csvg%20version%3D%221%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M12%209v3H9v7H6v-7H3V9h9zM8%204h14v3h-6v12h-3V7H8V4z%22%2F%3E%3C%2Fsvg%3E);
  opacity: 0.6;
}

.rdw-inline-wrapper.block-type .rdw-option-wrapper:nth-child(2) {
  background-image: url(data:image/svg+xml,%3Csvg%20version%3D%221%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M6%2017h3l2-4V7H5v6h3zm8%200h3l2-4V7h-6v6h3z%22%2F%3E%3C%2Fsvg%3E);
  opacity: 0.6;
}

.rdw-inline-wrapper.block-type .rdw-option-wrapper:nth-child(3) {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M18.2%2012L15%2015.2l1.4%201.4L21%2012l-4.6-4.6L15%208.8l3.2%203.2zM5.8%2012L9%208.8%207.6%207.4%203%2012l4.6%204.6L9%2015.2%205.8%2012z%22%2F%3E%3C%2Fsvg%3E);
  opacity: 0.6;
}

.rdw-editor-toolbar .rdw-option-wrapper {
  border: none;
  margin: 4px 0;

  @media (max-width: 1010px) and (min-width: 992px) {
    margin: 4px -1px;
  }
}

.save-and-next-btn {
  @media (max-width: 767px) {
    font-size: 1rem !important;
    margin-left: -2px !important;
  }

  @media (max-width: 992px) {
    font-size: 1rem !important;
  }
}

.education-submit-btn {
  @media (max-width: 767px) {
    font-size: 1rem;
  }
}

.alignment-container {
  margin-left: 5.25rem;
  margin-right: 5.25rem;

  @media (max-width: 991px) {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }

  @media (max-width: 767px) {
    margin-left: 10px;
    margin-right: 10px;
  }

  .headline-group {
    margin-bottom: 3rem;

    hgroup {
      line-height: 1.3;
    }

    @media (max-width: 767px) {
      width: 93%;
    }
  }
  .iPad-size {
    .col-lg-9 {
      @media screen and (max-width: 1179px) and (min-width: 992px) {
        padding-right: 9%;
      }
    }
  }
}

.is-mobile {
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
}

.is-desktop {
  display: block;

  @media (max-width: 767px) {
    display: none;
  }
}

.fbn {
  @media (max-width: 767px) {
    margin: 0 auto;
    position: fixed;
    width: 100%;
    background: $color-white;
    border-top: 1px solid #ddd;
    top: unset;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    padding: 10px;
    text-align: center;

    .btn-primary.intro-btn,
    .template-skip-btn,
    .btn-primary,
    .is-block.btn-primary {
      color: white;
      padding: 1.3rem 1.5rem;
      font-size: 1.5rem;
      max-width: 100%;
      width: 100%;
      font-weight: 500;
      background-color: $primary-color;
      border-color: $primary-color;
      font-family: "Rubik", sans-serif;
      text-transform: uppercase;
      margin-bottom: 0 !important;
    }
  }

  &.btn-justify {
    @media (max-width: 767px) {
      justify-content: space-between;
      display: flex;

      .btn-primary,
      .btn-outline-secondary {
        width: auto;
        font-size: 1.2rem;
        padding: 1.3rem 1.5rem;
      }
    }
  }
}

// input[type="text"]:focus {
//   outline: 1px solid #007aff;
// }
.modal-content {
  .modal-title.h4 {
    font-weight: bold;
    text-transform: uppercase;
    margin: 0.5rem 0 0 1.4rem;
  }

  .modal-body {
    padding: 2.4rem 2.4rem;
    font-family: "Open Sans TTF", sans-serif;

    color: #222;
    line-height: 1.5;
  }

  .modal-footer {
    padding-left: 1.4rem;
    padding-right: 1.4rem;
  }
}

// .save-n-next-btn {
//   @media (max-width: 991px) {
//     padding: 16px 37px !important;
//   }

//   @media (max-width: 767px) {
//     font-size: 0.95rem !important;
//     font-weight: $font-weight-semi;
//   }
// }

// button,
// input,
// optgroup,
// select,
// textarea {
//     color: inherit;
//     font: inherit;
//     margin: 0;
// }

// input {
//     line-height: normal;
// }

// input:not([type="submit"]),
// select,
// textarea,
// .input-file+label,
// .select2-container {
//     width: 100%;
//     max-width: 100%;
//     display: block;
//     color: #222;
//     border: 1px solid #c1c0c5;
//     border-radius: 4px;
//     font-size: 14px;
//     padding: 0.6em 1em;
//     transition: background 0.2s ease-out, border 0.2s ease-out;
//     background-image: none;
//     background-color: white !important;
//     margin-bottom: 0.2em;
//      @media (max-width:992px)
//      {
//          font-size: 20px;
//      }
// }
// input:last-child:not([type="submit"]),
// select:last-child,
// textarea:last-child,
// .input-file+label:last-child,
// .select2-container:last-child {
//     margin-bottom: 0;
//     font-size: 14px;
// }

// input:last-child:not([type="submit"]),
// select:last-child,
// textarea:last-child,
// .input-file+label:last-child,
// .select2-container:last-child {
//     margin-bottom: 0;
//     @media (max-width:992px)
//     {
//         font-size: 17px;
//     }
// }

.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  position: relative;
  width: 25px;
  height: 17px;
  right: 5px;
  top: -13px;

  @media (min-width: 992px) {
    display: none;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: $secondary-color;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  color: $secondary-color !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: $color-black;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: $color-white;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.bm-menu-wrap {
  top: 0px;
}

.writer__snippet-options.snippet-summary {
  display: block;

  .snippet-summary--dropdown {
    width: 100%;

    label {
      padding-right: 20px;
    }

    .summary-select {
      label,
      select {
        display: inline-block;
        width: auto;
      }
    }
  }
}

#exp-career option:first-child,
#edu-degreelist option:first-child,
#exp-sub-career option:first-child,
#graduation_month option:first-child,
#graduation_year option:first-child {
  display: none;
}

.option-select option:first-child {
  display: none;
}

.modal-footer .section-modal {
  padding: 6px 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.cancel-subheading h5 {
  font-weight: 700;
}

.user-settings .form-control:disabled,
.user-settings .form-control[readonly] {
  background-color: $color-white;
  opacity: 1;
}

.signup-error-message small {
  font-size: 1.4em;
}

@media (max-width: 767px) {
  .text-editor-container .col-sm-6 {
    width: 100%;
  }

  .editor-right-box {
    display: none;
  }

  .text-editor-container .rdw-editor-toolbar .rdw-list-wrapper {
    flex-grow: 0;
  }
}

.text-editor-container .rdw-editor-main {
  overflow: hidden;
}

.text-editor-container .public-DraftEditor-content {
  overflow: auto;
  min-height: 335px;
  max-height: 410px;
}

.text-editor-container .public-DraftEditor-content span {
  font-size: unset !important;
  font-family: unset !important;
}

@media (max-width: 767px) {
  .text-editor-container .public-DraftEditor-content {
    min-height: 335px;
    max-height: 335px;
  }
}

@media (max-width: 991px) {
  .custom--editor-section {
    width: 100%;
  }

  .other-editor-section {
    width: 100%;
  }
}

.rdw-editor-main .public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
  margin-left: 1.75em;
}

.rdw-editor-main .public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
  left: -30px;
}

.rdw-editor-wrapper .rdw-editor-toolbar {
  padding: 6px 2px 0;
  margin-bottom: 10px;
}

.rdw-inline-wrapper .rdw-option-wrapper img {
  width: 11px;
}

.rdw-option-wrapper img {
  width: 11px;
}

.rdw-inline-wrapper.block-type .rdw-option-wrapper div {
  width: 10px;
}

.google-login-button {
  background-color: #ea4335 !important;
  color: rgb(255, 255, 255);
  display: block;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 20px;
  line-height: 37px;
  position: relative;
  text-align: center;
  align-items: center;
  color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  padding: 0px;
  border-radius: 2px;
  border: 1px solid transparent;
  font-family: Roboto, sans-serif;
}

.google-login-button div {
  left: 0;
  line-height: normal;
  padding: 9px 10px 8px !important;
  position: absolute;
  margin-right: 10px;
  background: rgb(255, 255, 255);
  border-radius: 2px;
}

.progressbar {
  display: none;
}

// Siderbar Sticky
.footer {
  position: relative;
  z-index: 2;
}

@media (min-width: 992px) {
  // .header .navsteps .steps.sticky-list {
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   bottom: 0;
  //   width: 270px;
  //   display: block;
  //   z-index: 1;
  //   padding: 15px;
  //   border-right: 1px #ccc solid;
  //   background-color: #fff;
  //   padding-top: 85px;
  // }
  // .header .navsteps .steps.sticky-list li {
  //   margin: 28px 0;
  // }
  // .sidebar-sticky {
  //   width: 100%;
  //   max-width: 100%;
  //   padding-left: 270px;
  // }
  // .sidebar-sticky .alignment-container {
  //   margin-left: 2.25rem;
  // }
  // .sidebar-sticky.templates {
  //   width: 100%;
  //   max-width: 100%;
  //   padding-left: 270px;
  // }

  .header .navsteps .steps.sticky-list.cover-letter-sticky {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 270px;
    display: block;
    z-index: 1;
    padding: 15px;
    border-right: 1px #ccc solid;
    background-color: #fff;
    padding-top: 85px;
  }

  .header .navsteps .steps.sticky-list.cover-letter-sticky li {
    margin: 28px 0;
  }

  .sidebar-sticky.sidebar-sticky-cvrltr {
    width: 100%;
    max-width: 100%;
    padding-left: 270px;
  }

  .sidebar-sticky.sidebar-sticky-cvrltr .alignment-container {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }

  .sidebar-sticky.templates.sidebar-sticky-cvrltr {
    width: 100%;
    max-width: 100%;
    padding-left: 270px;
  }
}
@media (min-width: 768px) {
  .sidebar-sticky.templates.sidebar-sticky-cvrltr .btn-container.intro-footer {
    padding-top: 12%;
  }
}

// .header .navsteps .steps.sticky-list .progressbar {
//   display: block;
// }
.header .navsteps .steps.sticky-list.cover-letter-sticky .progressbar {
  display: block;
}

@media (max-width: 991px) {
  // .header .navsteps .steps.sticky-list .progressbar {
  //   display: none;
  // }
  .header .navsteps .steps.sticky-list.cover-letter-sticky .progressbar {
    display: none;
  }
}

// End Siderbar Sticky
.cvrLtr-header .btn-toolbar {
  display: none;
}
.loader-popup {
  .modal-content {
    border-radius: 6px;
  }

  .modal-body {
    padding: 15px 25px 15px;
  }

  .loader-popup-heading {
    padding-bottom: 15px;
  }

  .skeleton-img img {
    height: 100%;
  }

  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 90%;
      margin: 1.75rem auto;
    }
  }

  @media (min-width: 768px) {
    .loader-popup-heading {
      padding-bottom: 25px;
    }

    .modal-body {
      padding: 35px 50px 40px;
    }
  }

  @media (min-width: 992px) {
    .modal-lg,
    .modal-xl {
      max-width: 800px;
    }
  }

  @media (max-width: 767px) {
    h2 {
      font-size: 1.75rem;
    }
  }

  @media (max-width: 420px) {
    .loader-popup-heading {
      padding-bottom: 5px;
    }

    h2 {
      font-size: 1.5rem;
    }
  }
}

$loader-size: 80px;
$loader-dot-size: ($loader-size / 5); //20
$loader-height: $loader-dot-size; //20
$loader-dot-color: $secondary-color;

.loader {
  //border: 1px solid blue;
  width: $loader-size;
  height: $loader-height;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  > div {
    content: " ";
    // width: $loader-dot-size;
    // height: $loader-dot-size;
    width: 20px;
    height: 20px;
    // background: $loader-dot-color;
    border-radius: 100%;
    position: absolute;
    animation: shift 2s linear infinite;
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNSIgaGVpZ2h0PSIzNSIgdmlld0JveD0iMCAwIDM1IDM1Ij4NCiAgPGcgaWQ9IkxvYWRpbmdfdG9fUmlnaHRfQXJyb3ciIGRhdGEtbmFtZT0iTG9hZGluZyB0byBSaWdodCBBcnJvdyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTg0MSAtNDI5KSI+DQogICAgPGNpcmNsZSBpZD0iRWxsaXBzZV81IiBkYXRhLW5hbWU9IkVsbGlwc2UgNSIgY3g9IjE3LjUiIGN5PSIxNy41IiByPSIxNy41IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4NzYgNDI5KSByb3RhdGUoOTApIiBmaWxsPSIjMmZiZTZlIi8+DQogICAgPHBhdGggaWQ9IlBhdGhfMTM2IiBkYXRhLW5hbWU9IlBhdGggMTM2IiBkPSJNMTIsMTkuNVY0LjVtMCwwTDUuMjUsMTEuMjVNMTIsNC41bDYuNzUsNi43NSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoODcwLjc1IDQzNC41KSByb3RhdGUoOTApIiBmaWxsPSIjZmZmIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI0LjUiLz4NCiAgPC9nPg0KPC9zdmc+DQo=")
      no-repeat right center;
    background-size: 100% 100%;

    &:nth-of-type(1) {
      animation-delay: -0.4s;
    }

    &:nth-of-type(2) {
      animation-delay: -0.8s;
    }

    &:nth-of-type(3) {
      animation-delay: -1.2s;
    }

    &:nth-of-type(4) {
      animation-delay: -1.6s;
    }
  }
}

@keyframes shift {
  0% {
    left: -3 * $loader-dot-size;
    opacity: 0;
    background-color: $secondary-color;
  }

  10% {
    left: 1;
    opacity: 1;
  }

  90% {
    left: $loader-size;
    opacity: 1;
  }

  100% {
    left: ($loader-size + 3 * $loader-dot-size);
    background-color: $secondary-color-dark;
    opacity: 0;
  }
}

.mt-n1 {
  margin-top: -10px !important;
}

.pdf-review.loading:before {
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
  -webkit-animation-play-state: inherit;
  animation-play-state: inherit;
  border: 5px solid #cfd0d1;
  border-bottom-color: #1c87c9;
  border-radius: 50%;
  content: "";
  height: 40px;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  width: 40px;
  will-change: transform;
}

.pdf-review {
  // width: 170px;
  height: 272px;
  overflow: hidden;
  position: relative;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.pdf-review.apply-template-review {
  // width: 341px;
  // height: 404px;
  height: 323px;
  height: 100%;
}

.pdf-review .react-pdf__Page {
  min-width: auto !important;
}

.pdf-review .react-pdf__Page__canvas {
  height: 100% !important;
  margin: 0 auto;
  //width: 80%!important;
  width: 100% !important;
}

.react-pdf__Document .react-pdf__message--loading,
.react-pdf__Page__annotations,
.pdf-review .react-pdf__Page__textContent {
  display: none;
}

.writer__snippet-wrapper {
  max-height: 455px;
  overflow: hidden;
}

.pointer-hover:hover {
  border: 1px solid #07bc0c !important;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.cvltr-layout.cvltr-2 h1 {
  font-size: 2.6em;
}
.preview-section {
  // margin-top: 10rem;
  position: relative;
}

.btn-color {
  color: #f07201;
}

.prv-btn {
  border: 1px solid #c9c9c9;
  padding-left: 4px;
  border-radius: 4px;
  background: #f0f0f0;
}

@media screen and (min-width: 992px) {
  .scroll-sidebar {
    // max-height: calc(calc(var(--vh, 1vh) * 100) - 60px - 60px - 130px);
    // min-height: calc(calc(var(--vh, 1vh) * 100) - 60px - 60px - 130px);
    // max-height: 340px;
    min-height: 340px;
    margin-bottom: 0px !important;
  }
}
// .cvltr-preview-spacing{
//   max-height: 340px;
//   overflow: auto;
// }

.cvltr-list-wrapper::-webkit-scrollbar-thumb {
  background-color: $secondary-color !important;
}
.faq-hover {
  position: absolute;
  top: -55px;
  right: 0;
}
.signaturecolor {
  margin: 0px;
  outline: 0px;
  overflow: visible;
  user-select: none;
  text-decoration: inherit;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  padding: 0px 7px;
  width: auto;
  height: 40px;
  border: 1px solid transparent;
  border-radius: 4px;
  color: rgb(26, 26, 26);
  cursor: pointer;
  background: rgb(244, 244, 244);
}
.signaturecolor::after {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.4em solid;
  border-right: 0.4em solid transparent;
  border-bottom: 0;
  border-left: 0.4em solid transparent;
  margin-top: 5px;
}
.signaturecolor svg {
  width: 16px;
  height: 16px;
}
.signaturecolor svg {
  border: 1px solid black;
  border-radius: 50%;
  padding: 2px;
}
.cvltr-color .btn-color-group {
  display: flex;
  margin: 4px;
  flex-flow: wrap;
}
.cvltr-color .btn-color-div {
  display: flex;
  flex-direction: column;
  padding: 4px;
}
.cvrLtr-prv h1 {
  font-size: 1.8em !important;
}
.cvrLtr-prv h2 {
  font-size: 1.2em !important;
}
.cvrLtr-prv svg {
  width: 8px;
  height: 8px;
}
.cvrLtr-prv .cvltr-layout.cvltr-1 .cvltr-subheading,
.cvrLtr-prv .cvltr-layout.cvltr-8 .cvltr-graybackground p,
.cvrLtr-prv .cvltr-layout.cvltr-5 .cvltr-headingname,
.cvrLtr-prv .cvltr-layout.cvltr-8 .cvltr-graybackground h2,
.cvrLtr-prv .cvltr-layout.cvltr-2 .cvltr-headinginfo {
  padding: 2px;
}
.cvrLtr-prv .cvltr__signature span {
  font-size: 12px;
  padding: 0 !important;
}
.cvrLtr-prv .cvltr-preview-spacing {
  padding-left: 15px;
  padding-right: 15px;
}
.modal-content .cvtr-prv-modal.modal-body {
  padding: 2.4rem 8rem;
  min-height: 500px;
  max-height: 500px;
  overflow: auto;
}
.cvltr__signature img.sign-img {
  width: 120px;
}
.cvrLtr-prv .cvltr__signature img.sign-img {
  width: 55px;
}
.cvrLtr-prv .cvltr-preview-spacing::-webkit-scrollbar {
  width: 2px !important;
}
.cvrLtr-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-family: "Rubik", sans-serif;
  border-color: transparent;
}
@media (max-width: 767px) {
  #fc_frame.fc-widget-small {
    display: none;
  }
}

// Hide drap feature
// .cvltr-view-layout-box .resume__section .resume__move,
// .cvltr-view-layout-box .resume__section:hover .resume__move {
//   display: none !important;
// }
