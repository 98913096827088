
.modal-download-loader-section .form-group{
    text-align: center;
}
.modal-download-loader-section .resume-built{
    color: #2ebe6e;
    font-size: 1.75rem;
    font-weight: bold;
}
.modal-download-loader-section .waitfor_seconds{
    color: #2ebe6e;
    font-size: 1.75rem;
}
.modal-download-loader-section .loader-img{
    width: 85px;
    text-align: center;
    margin: 2rem auto;
}