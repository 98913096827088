.modal-delete-section {
   .form-group{
    position: relative;
    margin-bottom: 0rem;

.others_section{
	width: 3.5em;
}
input.input-inline {
		display: inline-block;
		width: auto;
		margin-right: 0.5em;
   }
	}
    .modal-footer{
        display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
        .add-new-section-btn{
            justify-content: left;
        }
    }

}