.dashboard {
    .box__header {
        border-bottom: 1px solid #c1c0c5;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        font-family: "Rubik", sans-serif;
    }

    .box_content {
        padding: 1.5rem 0;

        @media (min-width: 992px) {
            .dashboard-item {
                display: flex;
            }
        }

        .dashboard-select {
            margin-bottom: 2rem;

        }


        .dashboard-item__img {
            position: relative;
            height: 435px;
        }

        .resume-layout-box .resume__section:hover .resume__controls,
        .resume-layout-box .resume__section:hover .resume__move {
            display: none;
        }
    }

    @media(max-width:991px) {
        padding: 15px;
    }
}

// @media (min-width: 768px) and (max-width: 992px){
//     .col-md-4 {
//         flex-shrink: 0;
//         width: 100%;
//     }
// }

.resume-headline {
    border-bottom: 1px solid #EBEBEB;

}

.dashboard-item__img {
    .resume-layout .sub-headline .svg-icon svg {
        width: 1rem;
        height: 1rem;
    }

    .resume-layout.cv7 .sub-headline .svg-icon,
    .resume-layout.cv15 .sub-headline .svg-icon {
        padding: 0.25rem;
    }

    .resume-layout.cv7 .sub-headline .svg-icon svg,
    .resume-layout.cv15 .sub-headline .svg-icon svg {
        width: 0.65rem;
        height: 0.65rem;
    }
    .resume-layout .resume-photo {
        width: 65px;
        height: 65px;
    }
    .resume-layout.cv-bold .resume-photo {
        width: 95px;
        height: 95px;
    }
    .sidebar-bg {
        width: 37%;
    }
    .resume-layout.cv-bold .resume-photo-content {
        padding-right: 125px;
        min-height: 100px;
    }
    .resume-layout.cv-bold .resume__heading {
        margin-bottom: 1rem;
    }
    .resume-layout.cv-bold .resume-aside {
        padding-top: 1rem;
    }
    .resume-layout.cv-distinct .resume-photo-content {
        padding-left: 75px;
        padding-bottom: 10px;
        border-bottom: 3px #42a2e0 solid;
        min-height: 80px;
    }
    .resume-layout.cv-expressive .resume-photo-content {
        padding-right: 75px;
        min-height: 60px;
    }
    .resume-layout.cv-expressive .resume-header .info-main .info-item:first-child,
    .resume-layout.cv-distinct .resume-header .info-main .info-item:first-child {
        top: 18px;
    }
    .resume-layout.cv-expressive .resume__section,
    .resume-layout.cv-distinct .resume-layout-box .resume__section {
        margin-bottom: 1rem;
    }
    .resume-layout.cv-distinct h1 {
        margin-bottom: 0.25rem;
    }
}

.dashboard-item__img .resume-layout.cv19 .info-headline {
    top: -2.85rem !important;
    left: -2.85rem !important;
}

.dashboard-item__img .resume-layout h1 {
    font-size: 2.86em !important;
}

.dashboard-item__img .resume-layout.cv19 .sub-headline h2 {
    border-bottom: none !important;
}

.dashboard-item__img .resume-layout .resume-aside {
    width: 30% !important;
    margin-right: 0 !important;
}

.dashboard-item__img .resume-layout .resume-main {
    width: 70% !important;
}

.dashboard-item__img .resume-layout {
    font-size: 6.23771px;
}

.dashboard-item__img .resume-layout h2 {
    font-size: 1.43em !important;
}

@media (max-width: 992px) and (min-width:768px) {
    .dashboard-item__img {

        .resume,
        .resume-layout {
            font-size: 13.23771px;
        }
    }

}

.resume-layout .resume__others .wrap {
    display: block;
}