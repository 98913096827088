@import "../../assets/scss/variables";

.footer {
  font-size: 1.4rem;
  color: $color-white;
  background-color: $color-dark;
  font-family: $font-stack-body;

  @media (min-width: 768px) {
    font-size: 1.6rem;
  }

  .container {
    @media (min-width: 768px) {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
    }
  }

  .footer-wrap {
    padding: 2rem 0;

    @media (min-width: 992px) {
      padding: 5rem 0.5rem;
    }
  }


  &--logo {
    padding-bottom: 5px;

    @media (max-width: 768px) {
      text-align: center;
    }

    img {
      height: 43px;
      max-width: 100%;
    }
  }

  .footer-link {
    color: $color-white;
  }

  .footer-link:hover {
    color: $secondary-color;
  }

  &--link {
    margin-right: 8px;
    justify-content: space-between;


    @media (max-width: 479px) {
      text-align: center;
      display: block;
    }

    @media (max-width: 767px) {
      text-align: center !important;
    }

    @media (max-width: 991px) {
      padding-left: 20px;
      justify-content: space-between;
    }

    @media (max-width: 767px) {
      padding: 10px 0px;
      justify-content: center;
    }

    button {
      color: $color-white;
      margin: 0 2rem;
      font-family: $font-stack-body;

      @media (max-width: 479px) {
        margin: 5px 10px;
      }

      &:hover {
        color: $secondary-color;
      }
    }

    li:last-child {
      button {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  &--copyright {
    opacity: 0.4;
    color: $color-white;
    font-family: $font-stack-body;
    text-align: center;
    display: block;

    @media (min-width: 768px) {
      text-align: right;
      display: flex;
      display: -webkit-flex;
      justify-content: flex-end;
      -webkit-justify-content: flex-end;
    }

    @media (min-width: 992px) {
      text-align: left;
    }
  }
}