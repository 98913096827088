.cvltr-layout.cvltr-2 h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: normal;
  text-transform: capitalize;
  font-family: "Merriweather",serif;
}
.cvltr-layout.cvltr-2 {
  font-family: "Merriweather",serif;
}
.cvltr-layout.cvltr-2 .cvltr-headinginfo {
  background-color: #f5f5f5;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
.cvltr-layout.cvltr-2 .cvltr-headinginfo p {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 2px;
}
.cvltr-layout.cvltr-2 .cvltr-display-flex {
  display: flex;
  align-items: center;
}
.cvltr-layout.cvltr-2 .cvltr-d-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cvltr-layout.cvltr-2 .cvltr-justifycenter {
  justify-content: center;
}
.cvltr-layout.cvltr-2 .cvltr-companyinfo p {
  margin-bottom: 5px;
  margin-top: 0;
}
.cvltr-layout.cvltr-2 .cvltr-mt30 {
  margin-top: 30px;
}
.cvltr-layout.cvltr-2 .cvltr-mb30 {
  margin-bottom: 30px;
}
.cvltr-layout.cvltr-2 .cvltr-lable {
  display: none;
}
.cvltr-layout.cvltr-2 .cvltr-subheading {
  display: none;
}
.cvltr-layout.cvltr-2 .cvltr-img {
  display: none;
}
.cvltr-layout.cvltr-2 .cvltr-emaildisplay {
  display: none;
}
.cvltr-layout.cvltr-2 .cvltr-information {
  display: none;
}
.cvltr-layout.cvltr-2 .cvltr-dother-none {
  display: none;
}
.cvltr-layout.cvltr-2 .cvltr-headinginfo p {
  margin-right: 2px;
}
/* .cvltr-layout.cvltr-2 .show-comma {
  display: block;
} */
.cvltr-layout.cvltr-2 .flexwrap{
  flex-wrap: wrap !important;
}