@font-face {
  font-family: 'FontAwesome TTF';
  font-weight: 400;
  src: url("/assets/fonts/FontAwesome/fontawesome-TTF.ttf") format("truetype")
}
@font-face {
  font-family: 'Open Sans TTF';
  font-weight: 400;
  src: url("/assets/fonts/Open_Sans/OpenSans-Regular.ttf") format("truetype")
}
@font-face {
  font-family: 'Open Sans TTF';
  font-weight: 600;
  src: url("/assets/fonts/Open_Sans/OpenSans-SemiBold.ttf") format("truetype")
}
@font-face {
  font-family: 'Open Sans TTF';
  font-weight: 700;
  src: url("/assets/fonts/Open_Sans/OpenSans-Bold.ttf") format("truetype")
}
@font-face {
  font-family: 'Open Sans TTF';
  font-weight: 800;
  src: url("/assets/fonts/Open_Sans/OpenSans-ExtraBold.ttf") format("truetype")
}
@font-face {
  font-family: 'Merriweather';
  font-weight: normal;
  src: url("/assets/fonts/Merriweather/Merriweather-Regular.ttf") format("truetype")
}
@font-face {
  font-family: 'Merriweather';
  font-weight: normal;
  font-style: italic;
  src: url("/assets/fonts/Merriweather/Merriweather-Italic.ttf") format("truetype")
}
@font-face {
  font-family: 'Merriweather';
  font-weight: bold;
  src: url("/assets/fonts/Merriweather/Merriweather-Bold.ttf") format("truetype")
}
@font-face {
  font-family: 'Roboto TTF';
  font-weight: 300;
  src: url("/assets/fonts/Roboto/Roboto-Light.ttf") format("truetype")
}
@font-face {
  font-family: 'Roboto TTF';
  font-weight: normal;
  src: url("/assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype")
}
@font-face {
  font-family: 'Roboto TTF';
  font-weight: 500;
  src: url("/assets/fonts/Roboto/Roboto-Medium.ttf") format("truetype")
}
@font-face {
  font-family: 'Roboto TTF';
  font-weight: 600;
  src: url("/assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype")
}
@font-face {
  font-family: 'Roboto TTF';
  font-weight: 900;
  src: url("/assets/fonts/Roboto/Roboto-Black.ttf") format("truetype")
}
@font-face {
  font-family: 'PT Serif';
  font-weight: normal;
  src: url("/assets/fonts/PT_Serif/PT_Serif-Web-Regular.ttf") format("truetype")
}
@font-face {
  font-family: 'PT Serif';
  font-weight: bold;
  src: url("/assets/fonts/PT_Serif/PT_Serif-Web-Bold.ttf") format("truetype")
}
@font-face {
  font-family: 'Rubik';
  font-weight: 400;
  src: url("/assets/fonts/Rubik/Rubik-Regular.ttf") format("truetype")
}
@font-face {
  font-family: 'Rubik';
  font-weight: 500;
  src: url("/assets/fonts/Rubik/Rubik-Medium.ttf") format("truetype")
}
@font-face {
  font-family: 'Rubik';
  font-weight: 600;
  src: url("/assets/fonts/Rubik/Rubik-SemiBold.ttf") format("truetype")
}
// @font-face {
//   font-family: 'Bodoni MT';
//   font-weight: 600;
//   src: url("/assets/fonts/BodoniMt/Bodoni_MT.ttf") format("truetype")
// }
@font-face {
  font-family: 'Century Gothic';
  src: url("/assets/fonts/CenturyGothic/GOTHIC.ttf") format("truetype")
}
@font-face {
  font-family: 'Century Gothic';
  font-weight: 600;
  src: url("/assets/fonts/CenturyGothic/GOTHICB.ttf") format("truetype")
}
@font-face {
  font-family: 'Century Gothic';
  font-weight: 800;
  src: url("/assets/fonts/CenturyGothic/GOTHICB0.ttf") format("truetype")
}
// @font-face {
//   font-family: 'Georgia';
//   font-weight: 600;
//   src: url("/assets/fonts/georgia/georgia.ttf") format("truetype")
// }
@font-face {
  font-family: 'Palatino Linotype';
  font-weight: 600;
  src: url("/assets/fonts/palatino-linotype/palatino-linotype.ttf") format("truetype")
}
@font-face {
  font-family: 'Tahoma';
  font-weight: 600;
  src: url("/assets/fonts/tahoma/tahoma.ttf") format("truetype")
}
@font-face {
  font-family: 'Trebuchet MS';
  font-weight: 600;
  src: url("/assets/fonts/trebuchet/trebuc.ttf") format("truetype")
}