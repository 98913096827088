.modal-info-section {
    .modal-dialog {
        @media (min-width: 992px) {
            max-width: 600px;
        }
    }

    .modal-footer {
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        -webkit-align-items: center;
        justify-content: space-between;
        -webkit-justify-content: space-between;

        .textlink {
            color: #2ebe6e;
            text-align: inherit;

            &:hover {
                border-bottom: 1px solid;
            }
        }
    }
}