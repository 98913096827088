.resume-sidebox__inner {
    .form-group {
        margin-bottom: 1.2rem;
    }
}

.resume-sidebox__button-group {
    display: flex;
    justify-content: space-between;
    padding-top: 0;
}

.resume-sidebox__pill {
    padding: 1rem 0;
    position: relative;
}

// .resume-sidebox__button-group>* {
//     flex-basis: 53.36%;
// }

.slidecontainer {
    width: 100%;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 2px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    padding: 0!important;
}

.slider:hover,
.slider:focus {
    opacity: 1;
    border-color: #2ebe6e;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    box-shadow: 0 1px 10px rgb(0 0 0 / 39%);
    background: #ffff;
    border-radius: 50%;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 18px;
    height: 18px;
    box-shadow: 0 1px 10px rgb(0 0 0 / 39%);
    background: #ffff;
    border-radius: 50%;
    cursor: pointer;
}
.resume_font_family #select-font-family option:first-child {
display: none;
}