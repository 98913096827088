.dashboard-item__img {
    .resume {
        background-color: white;
        box-shadow: 0 1px 10px rgb(0 0 0 / 20%);
        border-width: 20px;
        height: 100%;
        padding-bottom: 1em;
        @media (min-width: 992px) {
            border-width: 1px;
        }
        .resume-layout {
            min-height: 300px !important;
            height: 379px;
            display: block;
            overflow: hidden;
        }
        .edit-resume {
            width: 100%;
            border-radius: 0;
        }
    }
}

.dashboard-item {
    .resume__controls {
        display: none;
    }
    .resume__move {
        display: none;
    }
}