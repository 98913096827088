.modal-custom-section {
   .form-group{
    position: relative;
    margin-bottom: 1rem;
    font-family: "Open Sans TTF", sans-serif;
    input[type="radio"] {
    position: absolute;
    height: 1px;
    margin: -1px;
		opacity: 0;
		height: 1.5em;
		z-index: 3;
}

input[type="radio"]+label {
    display: inline-block;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-right: 0.5em;
    text-transform: initial;
    letter-spacing: initial;
}

input[type="radio"]+label::before {
    content: '';
    display: inline-block;
    padding: 2px;
    width: 1.2em;
    height: 1.2em;
    margin-right: 0.5em;
    vertical-align: middle;
    border: 1px solid;
    border-radius: 50%;
    transition: all 0.3s ease-out, border-width 0.1s ease-in-out;
    transform: scale(1);
    background-color: transparent;
    background-clip: content-box;
    box-sizing: border-box;
    position: relative;
    top: -0.1em;
}

input[type="radio"]+label::before {
    text-decoration: none;
    display: inline-block;
}

input[type="radio"]:checked+label::before {
    transform: scale(1);
    border-width: 2px;
    background-color: #22252A;
}
.others_section{
	width: 3.5em;
}
input.input-inline {
		display: inline-block;
		width: auto;
		margin-right: 0.5em;
   }
	}
    .modal-footer{
        justify-content: left;
        .add-new-section-btn{
            justify-content: left;
        }
    }

}