
.cvltr-layout.cvltr-7 h1 {
  margin-top: 0;
}
.cvltr-layout.cvltr-7 .cvltr-headingdetails {
  margin-bottom: 30px;
}
.cvltr-layout.cvltr-7 .cvltr-headingdetails p {
  margin-top: 0;
  margin-bottom: 0;
}
.cvltr-layout.cvltr-7 .cvltr-tablewidth {
  width: 40%;
}
.cvltr-layout.cvltr-7 .cvltr-tablewidth h2 {
  margin-top: 0;
  margin-bottom: 0;
}
.cvltr-layout.cvltr-7 .cvltr-tdleftspacing {
  padding-left: 20px;
  border-right: 1px solid #dddddd;
}
.cvltr-layout.cvltr-7 .cvltr-tablealign {
  vertical-align: baseline;
}
.cvltr-layout.cvltr-7 .cvltr-companyinfo p {
  margin-bottom: 5px;
  margin-top: 0;
}
.cvltr-layout.cvltr-7 .cvltr-mt30 {
  margin-top: 30px;
}
.cvltr-layout.cvltr-7 .cvltr-mt0 {
  margin-top: 0px;
}
.cvltr-layout.cvltr-7 .cvltr-mb30 {
  margin-bottom: 30px;
}
.cvltr-layout.cvltr-7 .cvltr-mb-0 {
  margin-bottom: 0px;
}
.cvltr-layout.cvltr-7 .cvltr-twoside {
  display: flex;
  flex-direction: row-reverse;
  justify-content: start;
}
.cvltr-layout.cvltr-7 .cvltr-order2 {
  /* order: 2; */
  border-right: 0;
  padding-left: 20px;
}
.cvltr-layout.cvltr-7 .cvltr-tdrightspacing {
  padding-left: 0;
  padding-right: 20px;
  width: 60%;
}
.cvltr-layout.cvltr-7 .cvltr-subheading {
  display: none;
}
.cvltr-layout.cvltr-7 .cvltr-img {
  display: none;
}
.cvltr-layout.cvltr-7 .cvltr-emaildisplay {
  display: none;
}
.cvltr-layout.cvltr-7 .cvltr-information {
  display: none;
}
.cvltr-layout.cvltr-7 .cvltr-side-none {
  display: none;
}
.cvltr-layout.cvltr-7 .cvltr-lable::after {
  content: "\a";
  white-space: pre;
}
.cvltr-layout.cvltr-7 .cvltr-order-property {
  display: flex;
  flex-direction: column;
}
.cvltr-layout.cvltr-7 .cvltr-order-2 {
  -webkit-order: 2;
  order: 2;
}
.cvltr-layout.cvltr-7 .show-comma {
  display: none;
}

