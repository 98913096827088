.page-not-found {
  .not-found-img {
    margin: 0 auto 2rem;
    max-width: 350px;
  }
  .not-found-content {
    margin: 0 auto;
    padding: 0 1.5rem;
    max-width: 900px;
  }
}