
.cvltr-layout.cvltr-6 h1 {
  margin-top: 0;
}
.cvltr-layout.cvltr-6 .cvltr-headingdetails {
  margin-bottom: 30px;
}
.cvltr-layout.cvltr-6 .cvltr-headingdetails p {
  margin-top: 0;
  margin-bottom: 0;
}
.cvltr-layout.cvltr-6 .cvltr-tablewidth {
  padding-right: 10px;
  /* border-right: 1px solid #dddddd; */
  width: 30%;
}
.cvltr-layout.cvltr-6 .cvltr-tablewidth h2 {
  margin-top: 0;
  margin-bottom: 0;
}
.cvltr-layout.cvltr-6 .cvltr-tdleftspacing {
  padding-left: 20px;
  width: 70%;
  border-left: 1px solid #dddddd;
}
.cvltr-layout.cvltr-6 .cvltr-companyinfo p {
  margin-bottom: 5px;
  margin-top: 0;
}
.cvltr-layout.cvltr-6 .cvltr-mt30 {
  margin-top: 30px;
}
.cvltr-layout.cvltr-6 .cvltr-mt0 {
  margin-top: 0px;
}
.cvltr-layout.cvltr-6 .cvltr-mb30 {
  margin-bottom: 30px;
}
.cvltr-layout.cvltr-6 .cvltr-mb-0 {
  margin-bottom: 0px;
}
.cvltr-layout.cvltr-6 .cvltr-twoside {
  display: flex;
}
.cvltr-layout.cvltr-6 .cvltr-subheading {
  display: none;
}
.cvltr-layout.cvltr-6 .cvltr-img {
  display: none;
}
.cvltr-layout.cvltr-6 .cvltr-emaildisplay {
  display: none;
}
.cvltr-layout.cvltr-6 .cvltr-information {
  display: none;
}
.cvltr-layout.cvltr-6 .cvltr-side-none {
  display: none;
}
.cvltr-layout.cvltr-6 .cvltr-lable::after {
  content: "\a";
  white-space: pre;
}
.cvltr-layout.cvltr-6 .cvltr-order-property {
  display: flex;
  flex-direction: column;
}
.cvltr-layout.cvltr-6 .cvltr-order-2 {
  -webkit-order: 2;
  order: 2;
}
.cvltr-layout.cvltr-6 .show-comma {
  display: none;
}
