.box__header {
  margin-left: 47px;

  @media (max-width: 991px) {
    margin-left: 14px;
  }
}

.box__content {
  margin-left: 12px;
  margin-right: 12px;

  @media (max-width: 991px) {
    margin-left: 0px;
    margin-right: 0px;
  }
}
