@import "../../assets/scss/variables";

.plans-section {
  .alignment-container {
    @media (max-width: 991px) {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  .headline-group {
    margin-bottom: 2.5rem;

    @media (min-width: 992px) {
      margin-top: 36px;
    }

    @media screen and (min-width: 1200px) and (max-width: 1599px) {
      margin-top: 29px;
    }

    h1,
    h2 {
      margin-bottom: 3px;
      letter-spacing: .03em;
      font-size: 1.5rem;

      @media (max-width:991px) {
        font-size: 1.75rem;
      }

      @media (max-width: 767px) {
        font-size: 1.5rem;
      }
    }

    p {
      letter-spacing: .03em;

      @media (max-width:767px) {
        display: none;
      }
    }

    .plan-heading {
      font-size: 1.5rem;
      margin-top: 12px;

      @media (max-width:991px) {
        text-align: center;
        margin-top: 20px;
      }

      @media (max-width: 767px) {
        margin-top: 25px;
      }
    }

    .plan-sub-headline {
      margin: 0;
      font-family: 'Rubik', sans-serif;

      @media (max-width: 991px) {
        margin-bottom: 10px;
      }
    }

    .desktop-heading {
      @media (max-width:767px) {
        display: none;
      }
    }

    .mobile-heading {
      @media (min-width:768px) {
        display: none;
      }
    }

    .plan-btn {
      width: 94%;
      margin-left: 20px;

      @media (max-width:992px) {
        font-size: 1.75rem;
        width: 40.6%;
        margin: auto;
        padding: 1.25rem 1.5rem;
      }

      @media (max-width:767px) {
        width: 100%;
      }
    }
  }





  //plan page continue button 


  .container {
    @media (max-width: 991px) {
      max-width: 100%;
    }

    .alignment-container {
      @media (max-width: 991px) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  label {
    margin: 0;
    cursor: pointer;
  }

  .plan-radio {
    position: absolute;
  }

  .sr-only,
  input[type="checkbox"],
  input[type="radio"] {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
  }
}

.plan-desktop {
  margin-bottom: 50px;

  .headline-group {
    margin: 30px 0 0;
  }

  .pricing-wrap [class*=col-] {
    padding-left: 12px;
    padding-right: 12px;
  }

  .pricing {
    &-wrap {
      padding-top: 2.5rem;
      padding-bottom: 1.5rem;
      display: flex;
      justify-content: space-between;
      -webkit-justify-content: space-between;

      &--item {
        flex-basis: 32%;
        padding: 2rem;
        cursor: pointer;
        height: 100%;
        border: 1px solid #EBEBEB;

        &.box-active {
          border: 1px solid $secondary-color;

          .pricing-wrap--header {
            border-color: #2ebe6e;
          }
        }
      }

      &--header {
        padding-bottom: 3rem;
        border-bottom: 1px solid #EBEBEB;
        text-align: center;
        position: relative;

        .item-title {
          line-height: 1em;
          font-size: 1.8rem;
          text-transform: uppercase;

          span {
            display: block;
            text-transform: none;
          }
        }

        .item-price {
          display: block;
          font-size: 3rem;
          font-weight: 600;
          margin-top: 2rem;
        }
      }

      &--features {
        margin: 1.5rem 0 0;
        padding-left: 1.1em;
        font-size: inherit;

        li {
          color: $secondary-color;
          margin-bottom: 1rem;
          letter-spacing: -0.1px;

          span {
            color: $gray-dark;
          }
        }
      }
    }
  }
}

input.plan-radio[type="radio"]+label::before {
  position: absolute;
  top: -5px;
  right: -3px;
  content: "";
  width: 3.5rem;
  height: 3.5rem;
  // line-height: 28px;
  font-size: 2rem;
  z-index: 5;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid #EBEBEB;
  display: flex;
  justify-content: center;
  align-items: center;
}

input.plan-radio[type="radio"]:checked+.item-title:before {
  content: "\f00c";
  color: $secondary-color;
  font-family: "FontAwesome";
}

// Mobile 
.price-mobile {
  @media (max-width: 991px) {
    padding-left: 5px;
    margin-top: 30px;
  }

  @media (max-width: 767px) {
    margin-top: 15px;
  }

  .tab-content {
    padding-left: 0;
  }

  .nav-item {
    a {
      position: relative;
      height: auto;
      width: 100%;
      display: block;
      text-align: left;
      margin-bottom: 1rem;
      cursor: pointer;
      border-radius: 0;
      color: $gray-dark;
      padding: 2rem 1.5rem 2rem 3.75rem;

      @media (max-width: 479px) {
        font-weight: bold;
        padding: 1.67rem 1rem 1.67rem 3.5rem;
      }

      &:not(.active) {
        opacity: .5;
        border: 1px solid $gray-lighter;

        .item-title {
          font-weight: normal;

          @media (max-width: 479px) {
            font-weight: bold;

            span {
              font-weight: normal;
            }
          }
        }
      }

      &.active {
        border: 1px solid $gray-lighter;
        color: $gray-dark;
        background: none;
        box-shadow: 0 1px 5px rgb(0 0 0 / 10%);
      }

      &.box-active {
        border: 1px solid $secondary-color;

        .item-title {
          &::before {
            border: 1px solid #6A6666;
          }
        }
      }

      span {
        text-align: right;
        display: inline-block;
        right: 10px;
        position: absolute;
        top: 14px;
        font-size: 2rem;

        @media (max-width: 479px) {
          top: 17px;
        }
      }

      .item-title {
        font-size: 1.4375rem;
        text-transform: uppercase;

        @media (max-width: 479px) {
          font-size: 13px;
          font-weight: 700;
          letter-spacing: -0.6px;
        }

        &::before {
          top: 19px;
          left: 10px;
          width: 2.25rem;
          height: 2.25rem;
          line-height: 20px;
          font-size: 1.5rem;
          border: 1px solid #adadad;

          @media (max-width: 479px) {
            left: 10px;
            top: 17px;
            width: 2rem;
            height: 2rem;
            line-height: 17px;
          }
        }
      }

      .item-price {
        top: 25px;

        @media (max-width: 479px) {
          top: 14px;
        }
      }
    }
  }

  .pricing {
    &-wrap {
      &--features {
        margin: 0;
        padding: 13px 10px 10px 25px;
        font-size: 13.5px;
        box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
        border: 1px solid #EBEBEB;

        li {
          color: $secondary-color;
          margin-bottom: 0.87rem;
          font-size: 13px;

          span {
            color: $gray-dark;
          }
        }
      }
    }
  }
}

// Slider
.review-section {
  margin-bottom: 50px;
  padding-top: 40px;

  &--container {
    background-color: #f3f6f5;
    padding: 30px 0;

    .review-heading {
      padding-bottom: 30px;
      max-width: 565px;
      margin: 0 auto 20px;
      font-size: 44px;
      font-weight: 700;
      position: relative;
      text-align: center;
      font-family: "Open Sans TTF", sans-serif;

      @media (max-width: 991px) {
        font-size: 34px;
      }

      @media (max-width: 767px) {
        font-size: 22px;
        padding-bottom: 20px;
      }

      &::after {
        margin: 0 auto;
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 300px;
        height: 12px;
        background: rgba(15, 103, 203, 0.1);
        border-radius: 16px;

        @media (max-width: 767px) {
          width: 180px;
          height: 8px;
        }
      }
    }

    .review-widget {
      padding-top: 65px;
      padding-left: 55px;

      @media (max-width: 991px) {
        text-align: center;
        padding: 0;
      }

      img {
        @media (max-width: 991px) {
          max-width: 295px;
        }
      }
    }

    .review {
      margin: 30px 0;
      padding-right: 15px;

      @media (max-width: 991px) {
        margin: 0;
      }

      &--slider {
        padding: 0 30px;

        @media (max-width: 767px) {
          padding: 0 30px 0 37px;
        }


      }



      .swiper-slide {
        text-align: left;
        list-style: none;
        display: inline-block;
        min-height: 290px;
        padding: 15px;
        position: relative;
        vertical-align: top;
        white-space: normal;
        background-color: #fff;

        @media (max-width: 767px) {
          min-height: 250px;
          opacity: 0;
        }
      }

      .swiper-slide-active {
        @media (max-width:767px) {
          opacity: 1 !important;
        }
      }

      .review-stars {
        max-width: 140px;
        margin-bottom: 2px;
        height: 30px;

        @media (max-width: 1150px) {
          max-width: 100px;
          height: 18px;
        }
      }

      .review-header {
        margin: 10px 0 5px;
        display: block;
        font-size: 14px;
        font-weight: 700;
        text-overflow: ellipsis;
        width: 100%;
        line-height: 120%;
      }

      .review-text {
        font-size: 13px;
        line-height: 19px;
        color: rgba(0, 0, 0, 0.7);
        word-wrap: break-word;
      }

      .review-date,
      .review-name {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.4);
        position: absolute;
      }

      .review-date {
        right: 10px;
        top: 22px;
        font-size: 10px;
      }

      // html[dir='rtl'] .review .review-date {
      //   left: 10px;
      // }
      .review-name {
        bottom: 10px;
        /* height: 14px; */
        left: 15px;
        overflow: hidden;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        z-index: 2;
      }

      .tp-stars--5 .tp-star:nth-of-type(-n+5) .tp-star__canvas,
      .tp-stars--5 .tp-star:nth-of-type(-n+5) .tp-star__canvas--half {
        fill: #00b67a;
      }

      .tp-stars .tp-star__canvas,
      .tp-stars .tp-star__canvas--half {
        fill: #dcdce6;
      }



      .swiper-button-prev:after,
      .swiper-rtl .swiper-button-next:after,
      .swiper-button-next:after,
      .swiper-rtl .swiper-button-prev:after {
        font-size: 30px;
      }

      .swiper-button-next,
      .swiper-button-prev {
        top: 47%;
      }

      .swiper-button-next,
      .swiper-rtl .swiper-button-prev {
        right: 14px;
      }

      .swiper-button-prev,
      .swiper-rtl .swiper-button-next {
        left: 14px;
      }

      .swiper-button-prev {
        left: -5px;

        @media (max-width: 991px) {
          left: 0;
        }

        @media (max-width: 767px) {
          left: 8px;
        }
      }

      .swiper-button-next {
        right: 0;
      }


    }


  }
}

.moneyback {
  font-size: 13px;

  button {
    color: #2ebe6e;
    text-decoration: none;
  }
}