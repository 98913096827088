
.cvltr-layout.cvltr-1 h1 {
  margin-top: 0;
  color: #3ba1e3;
  text-align: center;
}
.cvltr-layout.cvltr-1 .cvltr-personalinfo {
  display: flex;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  padding-top: 10px;
  padding-bottom: 10px;
}
.cvltr-layout.cvltr-1 .cvltr-personalinfo svg {
  /* margin-top: 8px; */
  margin-right: 7px;
}
.cvltr-layout.cvltr-1 .cvltr-d-flex {
  display: flex;
  align-items: center;
}
.cvltr-layout.cvltr-1 .cvltr-justify {
  justify-content: space-between;
}
.cvltr-layout.cvltr-1 .cvltr-subheading {
  background-color: #3ba1e3;
  color: #ffffff;
  padding: 5px;
  margin-top: 20px;
}
.cvltr-layout.cvltr-1 .cvltr-subheading h2 {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 10px;
}
.cvltr-layout.cvltr-1 .cvltr-companyinfo p {
  margin-bottom: 5px;
  margin-top: 0;
}
.cvltr-layout.cvltr-1 .cvltr-mt30 {
  margin-top: 30px;
}
.cvltr-layout.cvltr-1 .cvltr-mb30 {
  margin-bottom: 30px;
}
.cvltr-layout.cvltr-1 .cvltr-mb5 {
  margin-bottom: 5px;
}
.cvltr-layout.cvltr-1 .cvltr-lable {
  display: none;
}
.cvltr-layout.cvltr-1 .cvltr-emailnone {
  display: none;
}
.cvltr-layout.cvltr-1 .cvltr-information {
  display: none;
}
.cvltr-layout.cvltr-1 .cvltr-dother-none {
  display: none;
}
.cvltr-layout.cvltr-1 .show-comma {
  display: none;
}
.cvltr-layout.cvltr-1 .cvltr-personalinfo p {  margin-top: 0 !important; margin-bottom: 0;}

.cvltr-layout.cvltr-1 .flexwrap{
  flex-wrap: wrap !important;
}

.cvltr-layout.cvltr-1 .info-item{
  width: 40%;
}
.cvltr-layout.cvltr-1 .info-item:last-child{
  width: 20%;
}
/* .cvltr-layout.cvltr-1 .cvltr-d-flex:last-child {
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
}
.cvltr-layout.cvltr-1 .cvltr-d-flex:nth-child(2){
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2;
}
.cvltr-layout.cvltr-1 .cvltr-d-flex:first-child {
  -webkit-order: 3;
  -ms-flex-order: 3;
  order: 3;
} */