:root {
  --primary-cvltr-gray-color: #F4F4F4;
  --primary-cvltr-gray-shade1-color: #fafafa;
  --primary-cvltr-gray-shade2-color: #f9fafb;
  --primary-cvltr-gray-shade3-color: #cdcdcd;
  --primary-cvltr-ltgray-color: #bdbdbd;
  --primary-cvltr-light-color: #E7F5FF;
  --primary-cvltr-white-color: #ffffff;
  --primary-cvltr-ltblack-color: #777777;
  --primary-cvltr-black-color: #222222;
  --primary-cvltr-darkblack-color: #111111;
  --secondary-cvltr-blue-color: #2196F3;
  --secondary-cvltr-ltblue-color: #0972C1;
  --secondary-cvltr-darkblue-color: #044F8A;
  --secondary-cvltr-orange-color: #f44336;
  --secondary-cvltr-orange-shade1-color: #fe8c26;
}

/* Layout */
$screen-xs: 575px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1024px;
$screen-xl: 1280px;

$screen-xxl: 1440px;
$screen-xxxl: 1600px;
$screen-large: 1920px;


/* Colors */
$base-color: #222222;

$primary-color: #fe8c26;
$primary-color-dark: #f07201;
$secondary-color: #2ebe6e;
$secondary-color-dark: #27a05d;

$color-light: #8892b0;
$color-dark: #203555;

$color-white: #ffffff;
$color-black: #000000;

// $color-text-light: #8892b0;
// $color-text-lighten: #ccd6f6;
// $color-text-dark: #0a192f;

/* Typography */
$font-stack-body: "Open Sans TTF", sans-serif;
$font-stack-heavy: 'Rubik', sans-serif;
$font-stack-heading: 'Rubik', sans-serif;
$line-height-base: 1.5;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$large-font-size: $font-size-base * 2 !default;
$extra-large-font-size: $font-size-base * 3 !default;
$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;
$font-size-small: 0.8750rem;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi: 600;
$font-weight-bold: 700;
$font-weight-heading: 600;

// Grays
$gray-darker: lighten(#000, 13.5%) !default; // #222
$gray-dark: lighten(#000, 20%) !default; // #333
$gray: lighten(#000, 33.5%) !default; // #555
$gray-light: lighten(#000, 50%) !default; // #999
$gray-lighter: lighten(#000, 80%) !default; // #ccc
$gray-lightest: lighten(#000, 90%) !default; // #eee


// Mixins/Function
@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin transform($args...) {
  -webkit-transform: $args;
  -moz-transform: $args;
  -ms-transform: $args;
  -o-transform: $args;
  transform: $args;
}