@import './variables';

html {
  position: relative;
  min-height: 100%;
  scroll-behavior: smooth;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -o-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

html,
body {
  height: 100%;
  // font-size: 100%;
  color: $base-color;
  font-family: $font-stack-body;
  font-weight: $font-weight-light;
  line-height: $line-height-base;
}
html {
  font-size: 62.5%;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px
}

body {
  font-size: 1.6rem;
  @media (min-width: 992px) {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1599px) {
  html {
      font-size: 8px
  }
  header .header__logo img {
      height: 35px;
      max-width: 100%
  }
  .hero__image {
      left: 55% !important;
      width: 550px !important
  }
}
p,
ul,
ol,
blockquote,
.content-block {
  margin-top: 0;
  margin-bottom: 1.8em
}

p+p,
p+ul,
p+ol,
p+blockquote,
p+.content-block,
ul+p,
ul+ul,
ul+ol,
ul+blockquote,
ul+.content-block,
ol+p,
ol+ul,
ol+ol,
ol+blockquote,
ol+.content-block,
blockquote+p,
blockquote+ul,
blockquote+ol,
blockquote+blockquote,
blockquote+.content-block,
.content-block+p,
.content-block+ul,
.content-block+ol,
.content-block+blockquote,
.content-block+.content-block {
  margin-top: -.8em
}

ul {
  padding-left: 1.1em
}

li {
  margin-bottom: .25em
}

blockquote {
  border-left: 4px solid #2ebe6e;
  padding-left: 1em;
  font-size: 1.8rem;
  font-weight: bold;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  max-width: 600px
}

h1,
.h1,
body.toolkit .typography__h1 {
  font-size: 2.6rem;
  margin-top: 0;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: .03em;
  font-weight: 600;
  line-height: 1.3;
  font-family: $font-stack-heading;
}

.heading-extra {
  font-weight: normal
}

@media (max-width: 460px) {
  .heading-extra {
      font-size: 3rem;
      display: block;
      margin-top: 1rem
  }
}

h2,
.h2,
body.toolkit .typography__h2 {
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: $font-stack-heading;
}

h3,
.h3 {
  font-size: 1.8rem;
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: $font-stack-heading;
}

h4,
.h4 {
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: 1rem;
}

h5,
.h5 {
  font-size: 1.8rem;
  margin-top: 0;
  margin-bottom: 1rem;
}

h6,
.h6 {
  font-size: 1.8rem;
  margin-top: 0;
  margin-bottom: 1rem;
}

.s1 {
  font-size: 2.6rem
}

.s2 {
  font-size: 2rem
}

.s3 {
  font-size: 1.8rem
}

.s4 {
  font-size: 1.5rem
}

.s5 {
  font-size: 1.6rem
}

.s5 {
  font-size: 1.3rem
}

.large {
  font-size: 2rem
}

.small,
body.toolkit .typography__small {
  font-size: 1.3rem
}

.xsmall {
  font-size: 1.2rem
}

hgroup>*:last-child {
  margin-bottom: 0
}

hgroup h1,
hgroup .s1 {
  margin-bottom: 0.5rem
}

hgroup h1+*,
hgroup .s1+* {
  font-family: 'Open Sans TTF', sans-serif;
  font-weight: normal
}

.muted {
  color: #a7a5ad
}



// Container 
.hero .header__container,
.navbar > .container,
.container {
  position: relative;
  max-width: 100%;
  width: 100%;
  @media (min-width: 460px) {
    max-width: 768px;
  }
  @media (min-width: 768px) {
    max-width: 990px;
  }
  @media (min-width: 992px) {
    max-width: 1240px;
  }
  @media (min-width: 1240px) {
    max-width: 1240px;
  }
  @media (min-width: 1400px) {
    max-width: 1240px;
  }
}




