@import "/src/assets/scss/variables";



.dashboard-item__info {
    margin-bottom: 7rem;
    @media (min-width: 992px) {
        margin-left: 8.33333%;
    }

    .dashboard-item__title {
        font-size: 3rem;
        font-weight: bold;
        display: block;
        margin-top: 0.55em;

        @media (max-width: 992px) {
            margin-top: 1.75em;
        }

        #resume-rename-btn {
            margin-left: 5px;
        }
    }

    .dashboard-item__edited {
        font-size: 1.4rem;
        font-weight: $font-weight-normal;
    }

    .resume-rename-btn {
        padding-left: 5px;
    }

    .muted {
        color: #2ebe6e;
    }

    .dashboard-item__actions:first-of-type {
        margin-top: 0.75em;
    }

    .dashboard-item__actions {
        border: 1px solid #EBEBEB;
        margin-bottom: 1.8rem;
        padding: 0 1em;
        font-weight: $font-weight-normal;
        display: flex;
        flex-wrap: wrap;

        @media (min-width: 992px) {
            margin-bottom: 1rem;
        }
        
        button {
            width: 100%;
            min-width: auto;
            margin: 0.75em 0;
            padding: 1em 1em;
            text-transform: uppercase;
        }

        .btn--icon {
            i {
                font-size: 1.8rem;
            }
        }
    }

    .create-resume {
        width: 100%;
        min-width: auto;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        font-size: 1.8rem;
    }
}